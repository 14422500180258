import { defineStore, acceptHMRUpdate } from "pinia";

export const useBookmarkStore = defineStore("bookmark", {
	state() {
		return {
			bookmarkList: [],
		};
	},
	getters: {
		hasBookmarks() {
			return this.bookmarkList.length;
		},
	},
	actions: {
		getBookmarks() {
			return $http
				.get(`account/bookmarks`)
				.then((response) => {
					this.bookmarkList = response.bookmarks;
					return response;
				})
				.catch((err) => console.error("getBookmarks failed", err));
		},
		addBookmark(payload) {
			return $http
				.post(`account/bookmarks`, payload)
				.then((response) => {
					if (response.id) {
						this.getBookmarks();
					}
					return response;
				})
				.catch((err) => console.error("addBookmark failed", err));
		},
		removeBookmark(id) {
			return $http
				.delete(`account/bookmarks/${id}`)
				.then((response) => {
					if (response.deleted) {
						this.getBookmarks();
					}
					return response;
				})
				.catch((err) => console.error("removeBookmark failed", err));
		},
	},
});

// enabling hmr for store
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useBookmarkStore, import.meta.hot));
}
