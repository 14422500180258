const ROUTES = [
	{
		path: "",
		name: "device-groups",
		component: () => import("./DeviceGroupsAll.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Device Groups",
			pageTitle: "Device Groups",
		},
	},
	{
		path: ":deviceGroupId",
		component: () => import("./DeviceGroupsId.vue"),
		props: true,
		children: [
			{
				path: "",
				redirect: { name: "device-groups-id-info" },
			},
			{
				path: "info",
				name: "device-groups-id-info",
				component: () => import("./DeviceGroupsIdInfo.vue"),
				beforeEnter: (to, from, next) => {
					if (to.params && to.params.deviceGroupId) {
						to.meta.pageTitle = `Device Group Info: ${to.params.deviceGroupId}`;
						next();
					} else {
						next();
					}
				},
			},
		],
	},
];

export default {
	path: "/device-groups",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		navTitle: "Device Groups",
		pageTitle: "Device Groups",
		requiresAuth: true,
		requiresPriv: "canViewMultiSites",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
