import { useUserStore } from "@/stores/store.user";

const ROUTES = [
	{
		path: "",
		name: "otas",
		beforeEnter: () => {
			const userStore = useUserStore();
			if (userStore.hasPriv("hasCustomerAccess")) {
				if (userStore.hasPriv("canAccessOTAs")) {
					return { name: "otas-history" };
				} else if (userStore.hasPriv("canViewOTATemplates")) {
					return { name: "otas-templates" };
				} else {
					return { name: "access-denied" };
				}
			} else {
				return { name: "otas-history" };
			}
		},
	},
	{
		path: "history",
		name: "otas-history",
		component: () => import("./OtasHistory.vue"),
		meta: {
			includeInNav: true,
			navTitle: "History",
			pageTitle: "OTAs History",
		},
	},
	{
		path: "orphans",
		name: "otas-orphans",
		component: () => import("./OtasOrphans.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Orphans",
			pageTitle: "OTA Orphans",
		},
	},
	{
		path: "templates",
		name: "otas-templates",
		component: () => import("./OtasTemplates.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Templates",
			pageTitle: "OTA Templates",
			requiresPriv: "canViewOTATemplates",
		},
		children: [
			{
				path: ":templateId",
				name: "otas-templates-id",
				props: true,
				component: () => import("./OtasTemplatesId.vue"),
				beforeEnter: (to, from, next) => {
					if (to.params && to.params.templateId) {
						to.meta.pageTitle = `OTA Template: ${to.params.templateId}`;
						next();
					} else {
						next();
					}
				},
				children: [
					{
						path: "manage",
						name: "otas-templates-id-manage",
						props: true,
						component: () => import("./OtasTemplatesIdManage.vue"),
						beforeEnter: (to, from, next) => {
							if (to.params && to.params.templateId) {
								to.meta.pageTitle = `Manage OTA Template: ${to.params.templateId}`;
								next();
							} else {
								next();
							}
						},
					},
					{
						path: "start",
						name: "otas-templates-id-start",
						props: true,
						component: () => import("./OtasTemplatesIdStart.vue"),
						beforeEnter: (to, from, next) => {
							if (to.params && to.params.templateId) {
								to.meta.pageTitle = `Start OTA Template: ${to.params.templateId}`;
								next();
							} else {
								next();
							}
						},
					},
				],
			},
		],
	},
	{
		path: "statistics",
		name: "otas-statistics",
		component: () => import("./OtasStatistics.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Statistics",
			pageTitle: "OTA Statistics",
		},
	},
	{
		path: ":otaId",
		name: "otas-id",
		component: () => import("./OtasId.vue"),
		props: true,
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.otaId) {
				to.meta.pageTitle = `OTA Job: ${to.params.otaId}`;
				next();
			} else {
				next();
			}
		},
	},
];

export default {
	path: "/otas",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: ["canAccessOTAs", "canViewOTATemplates"],
		navTitle: "OTAs",
		pageTitle: "OTAs",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
