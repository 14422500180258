import { defineStore, acceptHMRUpdate } from "pinia";
import * as Sentry from "@sentry/vue";

export const useUserStore = defineStore("user", {
	state() {
		return {
			account_create_remaining: 0,
			customer_id: null,
			customer_name: null,
			email: "",
			first_name: "",
			fullname: "",
			last_name: "",
			loggedIn: false,
			prefs: {},
			privs: {},
			redirectPath: null,
			sent_password_reset: false,
			sent_welcome_email: false,
			two_factor_confirm_required: false,
			two_factor_enable_required: false,
			two_factor_required: false,
			username: "",
			has_digi_solutions: false,
			is_production_deployment: false,
			password_age_days: 0,
			password_expires_in_days: 0,
			total_pictures_pending_approval: 0,
			//password component prop fields
			passwordUsername: "",
			passwordTooManyTries: false,
			passwordExpired: false,
		};
	},
	getters: {
		usersnapMeta: (state) => {
			return {
				username: state.username,
				fullname: state.fullname,
				customerId: state.customer_id,
				customerName: state.customer_name,
				email: state.email,
			};
		},
		hasPriv:
			(state) =>
			(priv, every = true) => {
				const privs = state.privs;
				function checkPriv(p) {
					return p in privs && privs[p];
				}

				if (Array.isArray(priv)) {
					if (every) {
						return priv.every(checkPriv);
					} else {
						return priv.some(checkPriv);
					}
				} else {
					return checkPriv(priv);
				}
			},
	},
	actions: {
		handleUserLogin(payload) {
			const params = {
				fetchPrefs: 1,
				fetchPrivs: 1,
				log_password: payload.log_password,
				log_username: payload.log_username,
			};
			const endpoint = payload.confirm ? `auth/2fa/confirm` : `auth/login`;

			if ("auth_token" in payload) {
				params.auth_token = payload.auth_token;
			}

			return $http
				.post(endpoint, params)
				.then((response) => {
					if (response && response.loggedIn) {
						this.updateUser(response);
					}
					return response;
				})
				.then((response) => {
					if (response && response.loggedIn) {
						if (this.redirectPath) {
							this.$router
								.push({
									path: this.redirectPath,
									replace: true,
								})
								.catch((err) => {
									console.error("router redirect", err);
								});
						} else if (response.prefs.router_landing_page_v3) {
							this.$router
								.push({
									path: response.prefs.router_landing_page_v3,
									replace: true,
								})
								.catch((err) => {
									console.error("router landing page", err);
								});
						} else {
							this.$router.push({ name: "home-page", replace: true });
						}
					}
					return response;
				})
				.catch((err) => console.error("handleUserLogin failed", err));
		},
		handleUserLogout() {
			const vm = this;
			return $http
				.post("auth/logout")
				.then((response) => {
					if (response.loggedOut) {
						vm.$reset();
						sessionStorage.removeItem("genesis-page-scope");
						this.$router.push({ name: "login", replace: true });
					}
					return response;
				})
				.catch((err) => console.error("handleUserLogout failed", err));
		},
		updateUserProfile(payload) {
			return $http
				.post("account/profile", payload)
				.then((response) => {
					this.updateUser(response);
					return response;
				})
				.catch((err) => console.error("updateUserProfile failed", err));
		},
		updateUserPrefs(payload) {
			return $http
				.post("account/prefs", payload)
				.then((response) => {
					this.updatePrefs(response);
					return response;
				})
				.catch((err) => console.error("updateUserPrefs failed", err));
		},
		//converted mutations from vuex
		updateUser(payload) {
			this.$patch(payload);

			if (payload && payload.username) {
				StonlyWidget("identify", payload.username, {
					email: payload.email,
					name: payload.fullname,
					"customer-id": payload.customer_id ? payload.customer_id + "" : "",
					"customer-name": payload.customer_name
						? payload.customer_name + ""
						: "",
					"can-access-igt-lux":
						payload && payload.privs && payload.privs.canViewIGTLuxembourgUnits
							? true
							: false,
					"is-internal":
						payload && payload.privs && payload.privs.hasInternalAccess
							? true
							: false,
				});

				Sentry.setUser({
					id: payload.username,
					username: payload.username,
					email: payload.email,
				});
				Sentry.setTag("customer_id", payload.customer_id);
				Sentry.setTag("customer_name", payload.customer_name);
			}
		},
		updatePrefs(payload) {
			let temp = {};

			Object.keys(payload).forEach((key) => {
				if (payload[key].updated) {
					temp[key] = payload[key].value;
				}
			});

			this.prefs = Object.assign(this.prefs, temp);
		},
		setLoginRedirect(payload) {
			this.redirectPath = payload;
		},
	},
});

// enabling hmr for store
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
