<template>
	<v-select
		v-model="value"
		:name="name"
		:items="updatedItems"
		:variant="$attrs.variant ? $attrs.variant : 'underlined'"
		:color="$attrs.color ? $attrs.color : 'primary'"
		:error-messages="errors"
		autocomplete="off"
	>
		<template
			v-for="(_, slotName) in $slots"
			:key="slotName"
			#[slotName]="slotProps"
		>
			<slot :name="slotName" v-bind="slotProps || {}" />
		</template>
	</v-select>
</template>

<script>
import { nanoid } from "nanoid";
import { useField } from "vee-validate";

export default {
	name: "VSelectWithValidation",
	props: {
		// eslint-disable-next-line vue/require-default-prop
		modelValue: {
			type: [String, Number, Boolean, Array, Object, Date, Function],
		},
		name: {
			type: String,
			default: function () {
				const uid = nanoid();
				return `VSelectWithValidation-${uid}`;
			},
		},
		items: {
			type: Array,
			default: () => [],
		},
		// Lookup endpoint to prefill
		// see: http://wiki.ventusnetworks.com/mediawiki/index.php/Genesis_Lookup
		lookup: {
			type: String,
			default: "",
		},
	},
	emits: ["update:modelValue"],
	setup(props) {
		const { value, errors, resetField } = useField(
			toRef(props, "name"),
			undefined,
			{
				initialValue: toRef(props, "modelValue"),
				syncVModel: false,
			},
		);

		return {
			value,
			errors,
			resetField,
		};
	},
	data() {
		return {
			updatedItems: this.items,
		};
	},
	watch: {
		value: function (newVal) {
			//in the case of reset through <Form>
			//resetForm from <Form> sets to undefined
			//using that hook to call resetField
			if (typeof newVal === "undefined") {
				this.resetField();
			} else if (this.modelValue !== newVal) {
				this.$emit("update:modelValue", newVal);
			}
		},
		modelValue: function (newVal) {
			if (this.value !== newVal) {
				this.value = newVal;
				this.$emit("update:modelValue", newVal);
			}
		},
		// Necessary for when 'items' is updated in the parent
		items: function (newItems) {
			this.updatedItems = newItems;
		},
	},
	created() {
		if (this.lookup) {
			$http.get(`lookup/${this.lookup}`).then((response) => {
				if (response && response[this.lookup]) {
					this.updatedItems = response[this.lookup];
				}
			});
		}
	},
};
</script>
