const ROUTES = [
	{
		path: "",
		name: "reporting",
		redirect: { name: "reporting-history" },
	},
	{
		path: "configure",
		name: "reporting-configure",
		component: () => import("./ReportingConfigure.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Configure",
			pageTitle: "Reporting Configure",
		},
	},
	{
		path: "scheduled",
		name: "reporting-scheduled",
		component: () => import("./ReportingScheduled.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Scheduled",
			pageTitle: "Reporting Scheduled",
		},
	},
	{
		path: "history",
		name: "reporting-history",
		component: () => import("./ReportingHistory.vue"),
		meta: {
			includeInNav: true,
			navTitle: "History",
			pageTitle: "Reporting History",
		},
		children: [
			{
				path: ":reportId",
				name: "reporting-history-id",
				component: () => import("./ReportingHistoryId.vue"),
				beforeEnter: (to, from, next) => {
					if (to.params && to.params.reportId) {
						to.meta.pageTitle = `Reporting History: ${to.params.reportId}`;
						next();
					} else {
						next();
					}
				},
				props: true,
			},
		],
	},
];

export default {
	path: "/reporting",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canAccessReporting",
		navTitle: "Reporting",
		pageTitle: "Reporting",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
