const ROUTES = [
	{
		path: "",
		name: "diagnostics",
		redirect: { name: "diagnostics-history" },
	},
	{
		path: "history",
		name: "diagnostics-history",
		component: () => import("./DiagnosticsHistory.vue"),
		meta: {
			includeInNav: true,
			navTitle: "History",
			pageTitle: "Diagnostics History",
		},
		children: [
			{
				path: ":diagnosticId",
				name: "diagnostics-history-id",
				component: () => import("./DiagnosticsHistoryId.vue"),
				props: true,
				beforeEnter: (to) => {
					if (to.params.diagnosticId) {
						to.meta.pageTitle = `Diagnostics History: ${to.params.diagnosticId}`;
					}
				},
			},
		],
	},
	{
		path: "tcp-capture-history",
		name: "diagnostics-tcp-capture-history",
		component: () => import("./DiagnosticsTcpCaptureHistory.vue"),
		meta: {
			includeInNav: true,
			navTitle: "TCP Capture History",
			pageTitle: "TCP Capture History",
		},
		children: [
			{
				path: ":tcpDiagnosticId",
				name: "diagnostics-tcp-capture-history-id",
				component: () => import("./DiagnosticsTcpCaptureHistoryId.vue"),
				props: true,
				beforeEnter: (to) => {
					if (to.params.tcpDiagnosticId) {
						to.meta.pageTitle = `TCP Capture History: ${to.params.tcpDiagnosticId}`;
					}
				},
			},
		],
	},
	{
		path: "start",
		name: "diagnostics-start",
		component: () => import("./DiagnosticsStart.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Start Diagnostic",
			pageTitle: "Start Diagnostic",
		},
		children: [
			{
				path: ":hostnames",
				name: "diagnostics-start-hostnames",
				component: () => import("./DiagnosticsStartHostnames.vue"),
				props: (route) => {
					return { hostnames: route.params.hostnames.split(",") };
				},
			},
		],
	},
];

export default {
	path: "/diagnostics",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canRunDiagnostics",
		navTitle: "Diagnostics",
		pageTitle: "Diagnostics",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
