<template>
	<svg
		class="stonly-SVG"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 172.96 162.34"
	>
		<rect
			class="stonly-SVG-shapes"
			x="51.85"
			y="0"
			width="70.02"
			height="36"
			rx="18"
			ry="18"
		/>
		<rect
			class="stonly-SVG-shapes"
			x="26.09"
			y="63.17"
			width="70.02"
			height="36"
			rx="18"
			ry="18"
		/>
		<rect
			class="stonly-SVG-shapes"
			x="51.85"
			y="126.34"
			width="121.11"
			height="36"
			rx="18"
			ry="18"
		/>
		<circle class="stonly-SVG-shapes" cx="129.63" cy="81.17" r="18" />
		<circle class="stonly-SVG-shapes" cx="18" cy="144.34" r="18" />
	</svg>
</template>

<script>
export default {
	data() {
		return {
			timer: null,
		};
	},
	mounted: function () {
		this.timer = setInterval(() => {
			const stonlyBtn = document.querySelector(".stonly-SVG");
			stonlyBtn.classList.add("anim-interval");
			stonlyBtn.addEventListener("animationend", () => {
				stonlyBtn.classList.remove("anim-interval");
			});
		}, 20000);
	},
	unmounted() {
		clearInterval(this.timer);
	},
};
</script>

<style lang="scss">
//$stonly-pink: #ff4a75;
$stonly-pink: rgb(var(--v-theme-stonlyPink));

.stonly-SVG {
	width: 18px;
	height: 18px;
	position: relative;
	top: 1px;
	cursor: pointer;
}
.stonly-SVG:hover .stonly-SVG-shapes {
	animation: stonlyHover 800ms 1 both ease-in;
}

.anim-interval .stonly-SVG-shapes {
	animation: stonlyInterval 3600ms 1 both ease-in;
}

.stonly-SVG-shapes {
	stroke: $stonly-pink;
	// stroke: rgb(var(--v-theme-primaryText));
	stroke-miterlimit: 10;
	stroke-width: 4px;
	fill: $stonly-pink;
	// fill: rgb(var(--v-theme-primaryText));
	transition: 600ms;
}

@keyframes stonlyHover {
	0% {
		fill: transparent;
		stroke-dasharray: 320px;
		stroke-dashoffset: 350px;
	}
	45% {
		fill: transparent;
	}
	80% {
		fill: rgb(var(--v-theme-primaryText));
		stroke: rgb(var(--v-theme-primaryText));
		stroke-dasharray: 320px;
		stroke-dashoffset: 0;
	}

	100% {
		fill: $stonly-pink;
		stroke: $stonly-pink;
	}
}
@keyframes stonlyInterval {
	0% {
		fill: transparent;
		stroke-dasharray: 320px;
		stroke-dashoffset: 350px;
	}
	8% {
		fill: transparent;
	}
	20% {
		fill: rgb(var(--v-theme-primaryText));
		stroke: rgb(var(--v-theme-primaryText));
		stroke-dasharray: 320px;
		stroke-dashoffset: 0;
	}

	30% {
		fill: $stonly-pink;
		stroke: $stonly-pink;
	}
	100% {
		fill: $stonly-pink;
		stroke: $stonly-pink;
	}
}
</style>
