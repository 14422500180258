import { useUserStore } from "@/stores/store.user";

export default [
	{
		path: "/",
		name: "home-page",
		component: () => import("./TheHomePage.vue"),
		meta: { requiresAuth: true, pageTitle: "Home" },
	},
	{
		path: "/access-denied",
		name: "access-denied",
		component: () => import("./TheAccessDenied.vue"),
		meta: {
			pageTitle: "Access Denied",
		},
	},
	{
		path: "/faq",
		name: "faq",
		component: () => import("./TheFaq.vue"),
		meta: {
			pageTitle: "FAQ",
		},
	},
	{
		path: "/login",
		name: "login",
		component: () => import("./TheLogin.vue"),
		//when the user is already logged in
		//redirect to home page
		beforeEnter: () => {
			const userStore = useUserStore();
			if (userStore.loggedIn) {
				return { name: "home-page" };
			}
		},
		props: true,
		meta: {
			pageTitle: "Login",
		},
	},
	{
		path: "/map",
		name: "map",
		component: () => import("./TheMap.vue"),
		meta: { requiresAuth: true, pageTitle: "Map" },
	},
	{
		path: "/not-found",
		name: "not-found",
		component: () => import("./TheNotFound.vue"),
		meta: {
			pageTitle: "Not Found",
		},
	},
	{
		path: "/browsers",
		name: "browsers",
		component: () => import("./TheBrowsers.vue"),
		meta: {
			pageTitle: "A Word On Browsers",
		},
	},
	{
		path: "/reset-password",
		name: "reset-password",
		component: () => import("./TheResetPassword.vue"),
		meta: { pageTitle: "Reset Password" },
	},
	{
		path: "/create-password/:token",
		name: "create-password-token",
		component: () => import("./TheResetPassword.vue"),
		props: (route) => ({
			token: route.params.token,
			isCreatingPassword: true,
		}),
		meta: { pageTitle: "Create Password" },
	},
	{
		path: "/reset-password/:token",
		name: "reset-password-token",
		component: () => import("./TheResetPassword.vue"),
		props: true,
		meta: { pageTitle: "Reset Password" },
	},
	{
		path: "/privacy",
		name: "privacy",
		component: () => import("./ThePrivacy.vue"),
		meta: {
			pageTitle: "Privacy",
		},
	},
	{
		path: "/learn-more",
		name: "learn-more",
		component: () => import("./TheLearnMore.vue"),
		meta: {
			pageTitle: "Learn More",
		},
	},
	{
		path: "/terms",
		name: "terms",
		component: () => import("./TheTerms.vue"),
		meta: {
			pageTitle: "Terms",
		},
	},
	{
		path: "/mobile-app",
		name: "mobile-app",
		component: () => import("./TheMobileApp.vue"),
		meta: {
			pageTitle: "Mobile App",
		},
	},
	{
		path: "/photo-management",
		name: "photo-management",
		component: () => import("./ThePhotoManagement.vue"),
		meta: {
			requiresAuth: true,
			requiresPriv: "canManageUploadedPhotos",
			pageTitle: "Photo Management",
		},
	},
];
