import {
	TICKET_SUBTYPES,
	TICKET_TYPES,
	TICKET_STATUSES,
	TICKET_SECONDARY_STATUSES,
	HOST_LOCATION_OPTIONS,
	ON_SITE_SUPPORT_OPTS,
	DIAGNOSES,
	SUBDIAGNOSES,
	RESOLUTION_CODES,
	REQUEST_RESOLUTION_CODES,
	SEVERITY_LEVELS,
} from "@/constants/TicketMeta.js";
import { useUserStore } from "@/stores/store.user";

export default function () {
	const userStore = useUserStore();

	//constants
	const HostLocationOptions = HOST_LOCATION_OPTIONS;
	const OnSiteSupportOptions = ON_SITE_SUPPORT_OPTS;
	const SeverityLevelOptions = SEVERITY_LEVELS;
	const DiagnosesOptions = DIAGNOSES;

	//refs
	const ticketFormObject = reactive({
		title: "",
		selectedType: null,
		selectedSubType: null,
		selectedPrimaryStatus: null,
		selectedSecondaryStatus: null,
		descriptionIsPublic: false,
		ticketDescription: "",
		carrierTicket: "",
		carrierAssetId: "",
		customerCaseId: "",
		usersToNotify: [],
		selectedHostLocations: [],
		assignSelf: true,
		userToAssign: "",
		selectedAssociation: null,
		unitAssociation: [],
		customerAssociation: null,
		networkObjectAssociaton: [],
		selectedOnSiteSupport: [],
		escalationLevel: null,
		severityLevel: null,
		selectedDiagnosis: null,
		selectedSubDiagnosis: null,
		selectedResolution: null,
		subscribedToTicket: false,
		updateReason: "",
		newAttachedUnits: [],
		newAttachedNetworkObjects: [],
		selectedCustomer: null,
	});

	//computeds
	const ticketPayload = computed(() => {
		const ticket = ticketFormObject;
		let payload = {
			title: ticket.title,
			idTicketType: ticket.selectedType,
			idSubType: ticket.selectedSubType,
			status: ticket.selectedPrimaryStatus,
			status2: ticket.selectedSecondaryStatus,
			desc: ticket.ticketDescription,
			is_public: ticket.descriptionIsPublic,
		};

		if (ticket.carrierTicket) {
			payload.carrierTicket = ticket.carrierTicket;
		}

		if (ticket.carrierAssetId) {
			payload.carrier_asset_id = ticket.carrierAssetId;
		}

		if (ticket.customerCaseId) {
			payload.customer_case_id = ticket.customerCaseId;
		}

		if (ticket.usersToNotify.length) {
			payload.notify_username = ticket.usersToNotify;
		}

		if (ticket.assignSelf) {
			payload.assignSelf = true;
		} else {
			payload.assignedUsername = ticket.userToAssign;
		}

		//selectedAssocation is used when creating a ticket in <TicketForm>
		if (ticket.selectedAssociation === "unit") {
			payload.hostname = ticket.unitAssociation;
		}
		if (ticket.selectedAssociation === "customer") {
			payload.customer_id = ticket.customerAssociation;
		}
		if (ticket.selectedAssociation === "network_object") {
			payload.network_object_id = ticket.networkObjectAssociaton;
		}

		if (
			userStore.hasPriv("canSetOnSiteSupport") &&
			ticket.selectedOnSiteSupport.length
		) {
			payload.on_site_support = ticket.selectedOnSiteSupport.join(",");
		}

		if (ticket.selectedHostLocations.length) {
			payload.host_location = ticket.selectedHostLocations.join(",");
		}

		//internal ticket special case when creating a ticket
		if (ticket.selectedType === 3) {
			if (ticket.selectedAssociation === "customer") {
				payload.customer_id = ticket.customerAssociation;
			} else {
				payload.customer_id = 1;
			}
		}

		if (ticket.severityLevel) {
			payload.severity_level = ticket.severityLevel;
		}

		if (ticket.escalationLevel) {
			payload.escalation_level = ticket.escalationLevel;
		}

		if (ticket.selectedDiagnosis) {
			payload.idDiagnosis = ticket.selectedDiagnosis;
		}

		if (ticket.selectedSubDiagnosis) {
			payload.idSubDiagnosis = ticket.selectedSubDiagnosis;
		}

		if (ticket.selectedResolution) {
			payload.idResolution = ticket.selectedResolution;
		}

		if (ticket.updateReason) {
			payload.reason = ticket.updateReason;
		}

		//when editing and has new units or network objects
		if (ticket.newAttachedUnits.length) {
			payload.attachHost = ticket.newAttachedUnits.map((item) => item.hostname);
		}
		if (ticket.newAttachedNetworkObjects.length) {
			payload.attachNetworkObject = ticket.newAttachedNetworkObjects.map(
				(item) => item.id,
			);
		}

		//when editing and want to change customer association
		if (!ticket.selectedAssociation) {
			payload.customer_id = ticket.selectedCustomer;
		}

		return payload;
	});
	const associationOptions = computed(() => {
		return GetAssociationOptions(
			ticketFormObject.selectedType,
			ticketFormObject.selectedSubType,
		);
	});
	const canAssignTicket = computed(() => {
		return (
			userStore.hasPriv("canAssignTicket") &&
			ticketFormObject.selectedType !== 5
		);
	});
	const canHaveCarrierTicket = computed(() => {
		return (
			ticketFormObject.selectedType === 1 ||
			ticketFormObject.selectedType === 3 ||
			ticketFormObject.selectedType === 4
		);
	});
	const resolutionCodeOptions = computed(() => {
		return GetResolutionCodes(
			ticketFormObject.selectedType,
			ticketFormObject.selectedSubType,
			ticketFormObject.selectedDiagnosis,
		);
	});

	//watchers
	// special cases for type
	// type === 1 -> "Problem",		subtype = 12 ( "Customer Requested Problem" )
	// type === 3 -> "Internal",	subtype = 3 ( "Internal" )
	// type === 4 -> "Other",		subtype = 4 ( "Other" )
	watch(
		() => ticketFormObject.selectedType,
		(val) => {
			if (val === 1 && userStore.hasPriv("hasCustomerAccess")) {
				ticketFormObject.selectedSubType = 12;
			} else if (val === 3) {
				ticketFormObject.selectedSubType = 13;
			} else if (val === 4) {
				ticketFormObject.selectedSubType = 14;
			}
		},
	);

	//methods
	function GetTicketTicketTypes() {
		if (userStore.hasPriv("hasInternalAccess")) {
			return TICKET_TYPES;
		} else {
			return TICKET_TYPES.filter((item) => item.code !== 3).filter(
				(item) => item.code !== 5 && !userStore.hasPriv("canCreateITTickets"),
			);
		}
	}
	function GetTicketSubTypes(typeCode) {
		return TICKET_SUBTYPES.filter((item) => item.typeCode === typeCode);
	}
	function GetAssociationOptions(typeCode, subtype) {
		let options = [];

		const UNITS = { label: `Unit`, val: `unit` };
		const CUSTOMER = { label: `Customer`, val: `customer` };
		const NETWORK_OBJECTS = {
			label: `Network Object`,
			val: `network_object`,
		};

		const isUnitType = typeCode === 1;
		const isRequestType = typeCode === 2;
		const isInternalType = typeCode === 3;
		const isOtherType = typeCode === 4;
		const isITType = typeCode === 5;

		const isUnitRequest =
			subtype === 7 || // OTA
			subtype === 8 || // Comm Check
			subtype === 9 || // Installation
			subtype === 30 || // Tunnel Bounce
			subtype === 31; // WAN IP Change
		const isCustomerRequest = subtype === 10 || subtype === 11; // Survey and Qual

		if (isUnitType) {
			options = [UNITS, NETWORK_OBJECTS, CUSTOMER];
		} else if (isRequestType) {
			if (isUnitRequest) {
				options = [UNITS, NETWORK_OBJECTS, CUSTOMER];
			} else if (isCustomerRequest) {
				options = [CUSTOMER];
			}
		} else if (isInternalType) {
			options = [UNITS, NETWORK_OBJECTS, CUSTOMER];
		} else if (isOtherType) {
			options = [CUSTOMER];
		} else if (isITType) {
			options = [];
		}

		return options;
	}
	function GetPrimaryStatuses(isCreateTicket, isBulkEdit) {
		if (isCreateTicket && !userStore.hasPriv("hasInternalAccess")) {
			//if creating and is customer
			return TICKET_STATUSES.filter((status) => status.code === 1);
		} else {
			if (isBulkEdit) {
				//if internal and bulk edit
				return TICKET_STATUSES;
			} else {
				//otherwise when creating or editing a single ticket
				return TICKET_STATUSES.filter((status) => status.code !== 2);
			}
		}
	}
	function GetSecondaryStatuses(statusCode, isCreateTicket) {
		const statuses = TICKET_SECONDARY_STATUSES.filter(
			(item) => item.statusCode === statusCode,
		);
		return isCreateTicket && !userStore.hasPriv("hasInternalAccess")
			? statuses.filter((status) => status.code === 2)
			: statuses;
	}
	function GetSubDiagnoses(typeCode) {
		return SUBDIAGNOSES.filter((item) => item.diagnosisCode === typeCode);
	}
	function GetResolutionCodes(typeCode, subTypeCode, diagnosisCode) {
		if (typeCode === 2) {
			return REQUEST_RESOLUTION_CODES.filter(
				(code) => code.subTypeCode === subTypeCode,
			);
		} else {
			return RESOLUTION_CODES.filter(
				(code) => code.diagnosisCode === diagnosisCode,
			);
		}
	}
	function GetTicket(id) {
		return $http
			.get(`tickets/${id}`)
			.then((response) => {
				return response;
			})
			.catch((err) => console.error("GetTicket failed", err));
	}
	function GetSeverityLevel(id) {
		return id
			? SEVERITY_LEVELS.find((item) => item.code === id).desc
			: "Not Specified";
	}
	function SubmitTicket(payload, id) {
		const url = id ? `tickets/${id}` : `tickets`;

		return $http
			.post(url, payload)
			.then((response) => response)
			.catch((err) => console.error("submitTicket failed", err));
	}
	function UpdateTicketSubscription(val, id) {
		let payload = {};
		if (val) {
			payload.subscribe = id;
		} else {
			payload.unsubscribe = id;
		}

		return $http
			.post(`tickets/subscriptions`, payload)
			.then(() => {
				$toast.success(
					`Successfully ${
						val ? "subcribed to" : "unsubscribed from"
					} this ticket.`,
					{
						icon: "fa-solid fa-check-circle",
					},
				);
			})
			.catch((err) => console.error("UpdateTicketSubscription failed", err));
	}
	function FillTicketFields(ticketData) {
		ticketFormObject.title = ticketData.ticketInfo.title;
		ticketFormObject.selectedType = parseInt(ticketData.ticketInfo.idType);
		ticketFormObject.selectedSubType = parseInt(
			ticketData.ticketInfo.idSubType,
		);
		ticketFormObject.selectedPrimaryStatus = parseInt(
			ticketData.ticketInfo.idStatus,
		);
		ticketFormObject.selectedSecondaryStatus = parseInt(
			ticketData.ticketInfo.idStatus2,
		);
		ticketFormObject.carrierTicket = ticketData.ticketInfo.carrierTicket;
		ticketFormObject.carrierAssetId = ticketData.ticketInfo.carrier_asset_id;
		ticketFormObject.customerCaseId = ticketData.ticketInfo.customer_case_id;
		ticketFormObject.selectedHostLocations =
			ticketData.ticketInfo.host_location;
		ticketFormObject.userToAssign = ticketData.ticketInfo.assignedUser;
		ticketFormObject.selectedOnSiteSupport =
			ticketData.ticketInfo.on_site_support;
		ticketFormObject.escalationLevel = ticketData.ticketInfo.escalation_level;
		ticketFormObject.severityLevel = ticketData.ticketInfo.severity_level;

		ticketFormObject.selectedDiagnosis = ticketData.ticketInfo.idDiagnosis
			? parseInt(ticketData.ticketInfo.idDiagnosis)
			: null;
		ticketFormObject.selectedSubDiagnosis = ticketData.ticketInfo.idSubDiagnosis
			? parseInt(ticketData.ticketInfo.idSubDiagnosis)
			: null;
		ticketFormObject.selectedResolution = ticketData.ticketInfo.idResolution
			? parseInt(ticketData.ticketInfo.idResolution)
			: null;
		ticketFormObject.subscribedToTicket = ticketData.ticketInfo.subscribed;
		ticketFormObject.updateReason = "";

		ticketFormObject.unitAssociation = ticketData.ticketInfo.units;
		ticketFormObject.networkObjectAssociaton =
			ticketData.ticketInfo.network_objects;

		ticketFormObject.selectedCustomer = ticketData.ticketInfo.customer.id;
	}
	function ParseTicketLogEntries(response) {
		return response.map((item) => {
			if (item.log_type === "created") {
				item.label = `Ticket Created`;
			} else if (item.log_type === "modified") {
				item.label = `Ticket Modified`;
			} else if (item.log_type === "attached_cil") {
				item.label = `CIL Entry Attached`;
			} else if (item.log_type === "attached_celox_module") {
				item.label = `Celox Module`;
			} else if (item.log_type === "attached_celox_site") {
				item.label = `Celox Site`;
			} else if (item.log_type === "attached_diag") {
				item.label = `Diagnostic Attached`;
			} else if (item.log_type === "attached_ota") {
				item.label = `OTA Attached`;
			} else if (item.log_type === "attached_remote_command") {
				item.label = `Remote Command Attached`;
			} else if (item.log_type === "attached_tcpdump") {
				item.label = `TCP Capture Attached`;
			} else if (item.log_type === "attached_note") {
				if (item.ref.cust_request_id && item.ref.cust_request_type) {
					item.label = `Response to Customer Request`;
				} else if (item.ref.is_description) {
					item.label = `Ticket Description`;
				} else {
					item.label = `Note Attached`;
				}
			} else if (item.log_type === "attached_cust_request") {
				item.label = `Customer Request Attached`;
			} else if (item.log_type === "attached_unit") {
				item.label = `Unit Attached`;
			} else if (item.log_type === "attached_network_object") {
				item.label = `Network Object Attached`;
			} else if (item.log_type === "attached_file") {
				item.label = `File Attached`;
			} else if (item.log_type === "attached_ticket") {
				item.label = `Ticket Attached`;
			}

			return item;
		});
	}
	function GetDiagnosisName(diagnosisCode) {
		return diagnosisCode
			? DIAGNOSES.find((code) => code.code === parseInt(diagnosisCode)).desc
			: "";
	}
	function GetSubDiagnosisName(diagnosisCode) {
		return diagnosisCode
			? SUBDIAGNOSES.find(
					(code) => code.diagnosisCode === parseInt(diagnosisCode),
				).desc
			: "";
	}
	function DeleteTicket(id) {
		return $http
			.post(`tickets/${id}`, { delete_ticket: 1 })
			.then((response) => {
				if (response.ticketUpdate) {
					$toast.success(`Successfully deleted your ticket!`);
				}
			})
			.catch((err) => console.error("DeleteTicket failed", err));
	}
	function ReopenTicket(id) {
		return $http
			.post(`tickets/${id}`, { status: 1 })
			.then((response) => response)
			.catch((err) => console.error("ReopenTicket failed", err));
	}
	function SubmitBatchTickets(payload) {
		return $http
			.post(`tickets/batch`, payload)
			.then((response) => response)
			.catch((err) => console.error("SubmitBatchTickets failed", err));
	}
	function ExportTicket(id) {
		return $http
			.get(`tickets/${id}/export`, {
				params: {
					filename: `GENESIS_Ticket_${id}_${new Date()}.txt`,
					timezone_id: userStore.prefs.time_zone,
				},
			})
			.then((response) => response)
			.catch((err) => console.error("ExportTicket failed", err));
	}

	return {
		//constants
		HostLocationOptions,
		OnSiteSupportOptions,
		SeverityLevelOptions,
		DiagnosesOptions,

		//refs
		ticketFormObject,

		//computeds
		ticketPayload,
		associationOptions,
		canAssignTicket,
		canHaveCarrierTicket,
		resolutionCodeOptions,

		//methods
		GetTicketTicketTypes,
		GetTicketSubTypes,
		GetAssociationOptions,
		GetPrimaryStatuses,
		GetSecondaryStatuses,
		GetSubDiagnoses,
		GetTicket,
		GetSeverityLevel,
		SubmitTicket,
		UpdateTicketSubscription,
		FillTicketFields,
		ParseTicketLogEntries,
		GetDiagnosisName,
		GetSubDiagnosisName,
		DeleteTicket,
		ReopenTicket,
		SubmitBatchTickets,
		ExportTicket,
	};
}
