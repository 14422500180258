const ROUTES = [
	{
		path: "",
		name: "tsc",
		redirect: { name: "tsc-needs-attention" },
	},
	{
		path: "needs-attention",
		name: "tsc-needs-attention",
		components: {
			default: () => import("./TscNeedsAttention.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Needs Attention",
			pageTitle: "TSC Needs Attention",
		},
	},
	{
		path: "logs",
		name: "tsc-logs",
		redirect: { name: "tsc-call-log" },
		components: {
			default: () => import("./TscLogs.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Logs",
			pageTitle: "TSC Logs",
			requiresPriv: "canAccessCallLogs",
		},
		children: [
			{
				path: "call-log",
				name: "tsc-call-log",
				component: () => import("./TscCallLog.vue"),
				meta: {
					navTitle: "Call Log",
					pageTitle: "TSC Call Log",
				},
			},
			{
				path: "email-log",
				name: "tsc-email-log",
				component: () => import("./TscEmailLog.vue"),
				meta: {
					navTitle: "Email Log",
					pageTitle: "TSC Email Log",
				},
			},
		],
	},
	{
		path: "chronic",
		name: "tsc-chronic",
		components: {
			default: () => import("./TscChronic.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Chronic",
			pageTitle: "TSC Chronic",
		},
	},
	{
		path: "cil",
		name: "tsc-cil",
		components: {
			default: () => import("./TscCil.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "CIL",
			pageTitle: "TSC CIL",
		},
	},
	{
		path: "follow-up",
		name: "tsc-follow-up",
		components: {
			default: () => import("./TscFollowUp.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Follow Up",
			pageTitle: "TSC Follow Up",
		},
	},
	{
		path: "notes",
		name: "tsc-notes",
		component: () => import("./TscNotes.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Internal Notes",
			pageTitle: "TSC Internal Notes",
		},
	},
	{
		path: "pending-resolution",
		name: "tsc-pending-resolution",
		components: {
			default: () => import("./TscPendingResolution.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Pending Resolution",
			pageTitle: "TSC Pending Resolution",
		},
	},
	{
		path: "adidas",
		name: "tsc-adidas",
		component: () => import("./TscAdidas.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Adidas",
			pageTitle: "TSC Adidas",
		},
	},
	{
		path: "replacement-requests",
		name: "tsc-replacement-requests-pending",
		components: {
			default: () => import("./TscReplacementRequestsPending.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Replacement Requests",
			pageTitle: "TSC Replacement Requests",
		},
	},
	{
		path: "replacement-requests-history",
		name: "tsc-replacement-requests-history",
		components: {
			default: () => import("./TscReplacementRequestsHistory.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Replacement Requests History",
			pageTitle: "TSC Replacement Requests History",
		},
	},
	{
		path: "router-stats",
		name: "tsc-router-stats",
		component: () => import("./TscRouterStats.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Router Stats",
			pageTitle: "TSC Router Stats",
		},
	},
];

export default [
	{
		path: "/tsc",
		component: () => import("@/components/Nav/NavRoute.vue"),
		meta: {
			requiresAuth: true,
			requiresPriv: "canAccessTSC",
			navTitle: "TSC",
			pageTitle: "TSC",
		},
		children: ROUTES,
		props: {
			navItems: ROUTES,
		},
	},
];
