import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({ showSpinner: false });

import { createRouter, createWebHistory } from "vue-router";
import Routes from "./routes";

import { useUserStore } from "@/stores/store.user";

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: Routes,
});

function verifyUserLogin() {
	return $http
		.get(`account/profile?${new Date().getTime()}`)
		.then((response) => {
			if (response) {
				const userStore = useUserStore();
				userStore.updateUser({ loggedIn: true });
				userStore.updateUser(response);
				return true;
			} else {
				return false;
			}
		})
		.catch(() => {
			//console.error("verifyUserLogin failed", err);
			return false;
		});
}

const userVerification = verifyUserLogin();

router.beforeEach(async (to) => {
	const userStore = useUserStore();
	await userVerification;

	//starting global progress
	NProgress.start();

	if (!userStore.loggedIn) {
		if (to.meta.requiresAuth) {
			// if not logged in, needs auth
			return { name: "login" };
		} else {
			// else not logged in, doesn't need auth
			// faq, privacy, etc
			return true;
		}
	} else {
		// dan is doing redirect from /login to / already
		// if (to.name === "login") {
		// 	// if logged in, redirect to map from login
		// 	return { name: "map", replace: true };
		// }
		if (to.meta.requiresPriv) {
			if (userStore.hasPriv(to.meta.requiresPriv, false)) {
				if (
					to.meta.requiresCustomer &&
					userStore.hasPriv("hasCustomerAccess") &&
					to.meta.requiresCustomer === userStore.customer_id
				) {
					return true;
				} else {
					// if requires some priv
					return true;
				}
			} else {
				// else does not have required privs
				return { name: "access-denied", replace: true };
			}
		} else {
			// else logged in and requires no privs
			return true;
		}
	}
});

router.afterEach((to, from) => {
	if (to.path && from.path && to.path !== from.path) {
		document.title = `Genesis | ${
			to.meta && to.meta.pageTitle ? to.meta.pageTitle : ""
		}`;
	}

	//ending global progress
	NProgress.done();
});

export default router;
