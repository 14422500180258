import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
import moment from "moment-timezone";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "@/plugins/yup-config";

import VShortkey from "vue-three-shortkey";

import * as Sentry from "@sentry/vue";

import { useUserStore } from "@/stores/store.user";

//app
const app = createApp(App);

//pinia
const pinia = createPinia();
pinia.use(({ store }) => {
	store.$router = markRaw(router);
});

//default toast options
const defaultToastOptions = {
	position: "top-center",
	timeout: 3000,
};

//privClass directive, used as v-priv-class
app.directive("privClass", (el, binding) => {
	//default behavior - if no value is provided, then check for internal access
	//args - type object, priv: string, class: string
	let classOverride;
	const userStore = useUserStore();

	//setting the class to use
	if (binding.value && binding.value.class) {
		classOverride = binding.value.class;
	} else {
		classOverride = "internal-access";
	}

	//checking if a priv was passed. if not, default to internal access
	if (binding.value && binding.value.priv) {
		if (userStore.hasPriv(binding.value.priv)) {
			el.classList.add(classOverride);
		} else {
			el.classList.remove(classOverride);
		}
	} else {
		if (userStore.hasPriv("hasInternalAccess")) {
			el.classList.add(classOverride);
		} else {
			el.classList.remove(classOverride);
		}
	}
});

Sentry.init({
	app,
	dsn: "https://d35f9a56b267eb962e1dcf04ac8adcb1@o70342.ingest.us.sentry.io/4507924623917056",
	integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration(),
	],
	release: __APP_VERSION__,
	environment: import.meta.env.MODE,
	// Tracing
	tracesSampleRate: 0.25,
	// Session Replay
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

app.use(pinia);
app.use(router);
app.use(vuetify);
app.use(Toast, defaultToastOptions);
app.use(VShortkey, { prevent: ["input", "textarea"] });
app.mount("#app");
