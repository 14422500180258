export default {
	path: "/cellular",
	name: "cellular",
	component: () => import("./CellularDataTable.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "can_view_cellular_plans",
		navTitle: "Cellular",
		pageTitle: "Cellular",
	},
};
