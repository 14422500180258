const ROUTES = [
	{
		path: "",
		name: "users",
		redirect: { name: "users-manage" },
	},
	{
		path: "manage",
		name: "users-manage",
		component: () => import("./UsersManage.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Manage Users",
			pageTitle: "Manage Users",
		},
	},
	{
		path: "pending-requests",
		name: "users-pending-requests",
		component: () => import("./UsersPendingRequests.vue"),
		meta: {
			includeInNav: true,
			requiresPriv: "hasInternalAccess",
			navTitle: "Pending User Requests",
			pageTitle: "Pending User Requests",
		},
	},
];

export default {
	path: "/users",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canAccessUsers",
		navTitle: "Users",
		pageTitle: "Users",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
