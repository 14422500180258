const ROUTES = [
	{
		path: "",
		name: "account",
		redirect: { name: "account-info" },
	},
	{
		path: "info",
		name: "account-info",
		component: () => import("./AccountInfo.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Account Info",
			pageTitle: "Account Info",
		},
	},
	{
		path: "password",
		name: "account-password",
		component: () => import("./AccountPassword.vue"),
		meta: {
			includeInNav: true,
			pageTitle: "Account Password",
			navTitle: "Password",
		},
	},
	{
		path: "api-keys",
		name: "account-api-keys",
		component: () => import("./AccountApiKeys.vue"),
		meta: {
			includeInNav: true,
			navTitle: "API Keys",
			pageTitle: "Account API Keys",
			requiresPriv: "canViewNormalPreferences",
		},
	},
];

export default {
	path: "/account",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: { requiresAuth: true, navTitle: "Account", pageTitle: "Account" },
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
