const ROUTES = [
	{
		path: "",
		name: "tickets",
		redirect: { name: "tickets-all" },
	},
	{
		path: "all",
		name: "tickets-all",
		component: () => import("./TicketsAll.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Tickets",
			pageTitle: "Tickets",
		},
	},
	{
		path: "statistics",
		name: "tickets-statistics",
		component: () => import("./TicketsStatistics.vue"),
		meta: {
			includeInNav: true,
			requiresPriv: "canAccessTicketsStats",
			navTitle: "Statistics",
			pageTitle: "Ticket Statistics",
		},
	},
	{
		path: ":ticketId",
		component: () => import("./TicketsId.vue"),
		name: "tickets-id",
		props: true,
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.ticketId) {
				to.meta.pageTitle = `Ticket: ${to.params.ticketId}`;
				next();
			} else {
				next();
			}
		},
	},
];

export default {
	path: "/tickets",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canAccessTickets",
		navTitle: "Tickets",
		pageTitle: "Tickets",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
