const ROUTES = [
	{
		path: "",
		name: "monitoring",
		redirect: { name: "monitoring-outages" },
	},
	{
		path: "outages",
		name: "monitoring-outages",
		component: () => import("./MonitoringOutages.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Outages",
			pageTitle: "Monitoring Outages",
		},
		children: [
			{
				path: ":outageId",
				name: "monitoring-outages-id",
				component: () => import("./MonitoringOutagesId.vue"),
				props: true,
				beforeEnter: (to, from, next) => {
					if (to.params && to.params.outageId) {
						to.meta.pageTitle = `Monitoring Outage: ${to.params.outageId} Details`;
						next();
					} else {
						next();
					}
				},
			},
		],
	},
	{
		path: "outage-chart",
		name: "monitoring-outage-chart",
		component: () => import("./MonitoringOutageChart.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Outage Chart",
			pageTitle: "Monitoring Outage Chart",
		},
	},
	{
		path: "failover",
		name: "monitoring-failover",
		component: () => import("./MonitoringFailover.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Failover",
			pageTitle: "Monitoring Failover",
		},
	},
];

export default {
	path: "/monitoring",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canAccessMonitoring",
		navTitle: "Monitoring",
		pageTitle: "Monitoring",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
