import { useUserStore } from "@/stores/store.user";

const CID_ROUTES = [
	{
		path: "dash",
		name: "customer-dash-id-dash",
		component: () => import("./CustomerDashIdDash.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Dash",
			pageTitle: "Customer Dash",
		},
		props: true,
	},
	{
		path: "tags",
		name: "customer-dash-id-tags",
		component: () => import("./CustomerDashIdTags.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Tags",
			pageTitle: "Customer Dash Tags",
			requiresPriv: "canManageCustomerTags",
		},
		props: true,
	},
	{
		path: "signal-limits",
		name: "customer-dash-id-signal-limits",
		component: () => import("./CustomerDashIdSignalLimits.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Signal Limits",
			pageTitle: "Customer Dash Signal Limits",
			requiresPriv: "canModifySignalLimits",
		},
		props: true,
	},
	{
		path: "ticket-automation",
		name: "customer-dash-id-ticket-automation",
		component: () => import("./CustomerDashIdTicketAutomation.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Ticket Automation",
			pageTitle: "Customer Dash Ticket Automation",
			requiresPriv: "canAdminCustomerTicketAutomation",
		},
		props: true,
	},
	{
		path: "settings",
		name: "customer-dash-id-settings",
		component: () => import("./CustomerDashIdSettings.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Settings",
			pageTitle: "Customer Dash Settings",
			requiresPriv: "canAdminCustomerSettings",
		},
		props: true,
	},
];

export default [
	{
		path: "/customer-dash",
		name: "customer-dash",
		component: () => import("./CustomerDashNav.vue"),
		beforeEnter: () => {
			//if user is customer, set customer id and re-route
			const userStore = useUserStore();
			if (userStore.hasPriv("hasCustomerAccess")) {
				return {
					name: "customer-dash-id",
					params: { customerId: userStore.customer_id },
				};
			}
		},
		meta: {
			requiresAuth: true,
			pageTitle: "Customer Dash: Select Customer",
		},
	},
	{
		path: "/customer-dash/:customerId",
		name: "customer-dash-id",
		redirect: { name: "customer-dash-id-dash" },
		meta: {
			requiresAuth: true,
			pageTitle: "Customer Dash",
		},
		component: () => import("./CustomerDashId.vue"),
		beforeEnter: (to) => {
			//if user is customer and their customer id does
			//not match the id in the url then re-route
			const userStore = useUserStore();
			if (
				userStore.hasPriv("hasCustomerAccess") &&
				to.params.customerId &&
				parseInt(to.params.customerId) !== parseInt(userStore.customer_id)
			) {
				return { name: "customer-dash" };
			}
		},
		props: (route) => {
			//casting to int, since params are default casted to string
			const customerId = Number.parseInt(route.params.customerId, 10);
			return { customerId: customerId, navItems: CID_ROUTES };
		},
		children: CID_ROUTES,
	},
];
