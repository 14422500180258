import $axios from "@/plugins/axios-instance";
import { useToast } from "vue-toastification";
import moment from "moment-timezone";
import { useUserStore } from "@/stores/store.user";
import bytes from "bytes";

//common constants
export const $datepickerToday = new Date().toISOString().split("T")[0];
export const $datepickerTodayMonth = new Date()
	.toISOString()
	.split("T")[0]
	.substring(0, 7);
export const $defaultTimeFormat = "YYYY/MM/DD hh:mm:ss A";

//moment specific functions
export const $dateToUnix = (value) => {
	return Math.floor(value.getTime() / 1000);
};
export const $momentFormatTime = (value, format, timezone) => {
	const dateFormat = !format ? "YYYY/MM/DD hh:mm:ss A" : format;

	if (value === null || value === undefined || dateFormat === undefined) {
		return "";
	}

	//need this case for unix timestamps
	if (Number.isInteger(value) && moment(value, "X").isValid()) {
		if (dateFormat === "from") {
			return moment.unix(value).fromNow();
		}

		return timezone
			? moment.unix(value).tz(timezone).format(dateFormat)
			: moment.unix(value).format(dateFormat);
	} else {
		if (dateFormat === "from") {
			return moment(value).fromNow();
		}

		return timezone
			? moment(value).tz(timezone).format(dateFormat)
			: moment(value).format(dateFormat);
	}
};
export const $momentFormatDuration = (value, metric, settings) => {
	let defaultSettings = {
		template: "d[d] : h[h] : m[m] : s[s]",
		trim: "both",
	};

	if (value === null || value === undefined || !metric) {
		return "";
	}

	return moment
		.duration(value, metric)
		.format(settings ? settings : defaultSettings);
};

//user privilege functions
export const $userHasPriv = (priv, every = true) => {
	const userStore = useUserStore();
	return userStore.hasPriv(priv, every);
};

//string format functions
export const $stringDefault = (val, customString) => {
	const string = customString ? customString : "n/a";

	if (!val) {
		return string;
	} else if (typeof val === "string" || val instanceof String) {
		return val && val.length ? val : string;
	} else {
		return val;
	}
};
export const $readableBoolean = (value) => {
	if (value === undefined || value === null) {
		return "";
	} else {
		return !value ? "No" : "Yes";
	}
};
export const $renderMonetaryValue = (value, prefix) => {
	const temp = Number.isFinite(value);
	if (temp) {
		if (prefix) {
			return `${prefix}${value}`;
		} else {
			return temp;
		}
	} else {
		return ``;
	}
};
export const $byteFormat = bytes;

//other functions
export const $getPageScopeSession = () => {
	const session = sessionStorage.getItem("genesis-page-scope");
	let temp;

	if (session) {
		try {
			temp = JSON.parse(session);
		} catch {
			sessionStorage.removeItem("genesis-page-scope");
		}
	}
	return temp;
};

//global exposed methods
export const $http = $axios;
export const $toast = useToast();
