import { useUserStore } from "@/stores/store.user";

const CID_ROUTES = [
	{
		path: "plan-management",
		name: "ordering-id-plan-management",
		props: true,
		meta: { requiresPriv: "canChangePlan" },
		component: () => import("./OrderingIdPlanManagement.vue"),
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.customerId) {
				to.meta.pageTitle = `Ordering Plan Management: ${to.params.customerId}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "plan-change-history",
		name: "ordering-id-plan-change-history",
		props: true,
		component: () => import("./OrderingIdPlanChangeHistory.vue"),
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.customerId) {
				to.meta.pageTitle = `Ordering Plan Change History: ${to.params.customerId}`;
				next();
			} else {
				next();
			}
		},
	},
];

export default [
	{
		path: "/ordering",
		name: "ordering",
		component: () => import("./OrderingNav.vue"),
		meta: {
			requiresAuth: true,
			requiresPriv: "canAccessOrdering",
			pageTitle: "Ordering: Select Customer",
		},
		beforeEnter: () => {
			//if user is customer, set customer id and re-route
			const userStore = useUserStore();
			if (userStore.hasPriv("hasCustomerAccess")) {
				return {
					name: "ordering-id",
					params: { customerId: userStore.customer_id },
				};
			}
		},
	},
	{
		path: "/ordering/:customerId",
		name: "ordering-id",
		redirect: { name: "ordering-id-plan-management" },
		meta: {
			requiresAuth: true,
			requiresPriv: "canAccessOrdering",
			pageTitle: "Ordering",
		},
		component: () => import("./OrderingId.vue"),
		beforeEnter: (to) => {
			//if user is customer and their customer id does
			//not match the id in the url then re-route
			const userStore = useUserStore();
			if (
				userStore.hasPriv("hasCustomerAccess") &&
				to.params.customerId &&
				parseInt(to.params.customerId) !== parseInt(userStore.customer_id)
			) {
				return { name: "ordering" };
			}
		},
		props: (route) => {
			//casting to int, since params are default casted to string
			const customerId = Number.parseInt(route.params.customerId, 10);
			return { customerId: customerId };
		},
		children: CID_ROUTES,
	},
];
