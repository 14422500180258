const ROUTES = [
	{
		path: "",
		name: "notifications",
		redirect: { name: "notifications-history" },
	},
	{
		path: "create",
		name: "notifications-create",
		component: () => import("./NotificationsCreate.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Create",
			hideHeader: true,
			pageTitle: "Create Notifications",
			requiresPriv: "canCreateNotifications",
		},
	},
	{
		path: "manage",
		name: "notifications-manage",
		component: () => import("./NotificationsManage.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Manage",
			pageTitle: "Manage Notifications",
		},
	},
	{
		path: "history",
		name: "notifications-history",
		component: () => import("./NotificationsHistory.vue"),
		meta: {
			includeInNav: true,
			navTitle: "History",
			pageTitle: "Notification History",
		},
	},
	{
		path: "units",
		name: "notifications-units",
		component: () => import("./NotificationsUnits.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Units",
			pageTitle: "Manage Unit Notification Alerts",
			requiresPriv: "canCreateNotifications",
		},
	},
	{
		path: "geofencing",
		name: "notifications-geofencing",
		component: () => import("./NotificationsGeofencing.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Geofencing",
			pageTitle: "Manage Unit Geofencing Alerts",
		},
	},
];

export default {
	path: "/notifications",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		navTitle: "Notifications",
		pageTitle: "Notifications",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
