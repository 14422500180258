import AccountRoutes from "./Account/Routes.Account.js";
import AssetManagementRoutes from "./AssetManagement/Routes.AssetManagement.js";
import CellularRoutes from "./Cellular/Routes.Cellular.js";
import CustomerDashRoutes from "./CustomerDash/Routes.CustomerDash.js";
import DeviceGroupsRoutes from "./DeviceGroups/Routes.DeviceGroups.js";
import DiagnosticsRoutes from "./Diagnostics/Routes.Diagnostics.js";
import EngineeringRoutes from "./Engineering/Routes.Engineering.js";
import InstallationsRoutes from "./Installations/Routes.Installations.js";
import MonitoringRoutes from "./Monitoring/Routes.Monitoring.js";
import NetworkObjectsRoutes from "./NetworkObjects/Routes.NetworkObjects.js";
import NetworkRoutes from "./Network/Routes.Network.js";
import NotificationsRoutes from "./Notifications/Routes.Notifications.js";
import OrderingRoutes from "./Ordering/Routes.Ordering.js";
import OtasRoutes from "./Otas/Routes.Otas.js";
import ReportingRoutes from "./Reporting/Routes.Reporting.js";
//import SchedulingRoutes from "./Scheduling/Routes.Scheduling.js";
import SitesRoutes from "./Sites/Routes.Sites.js";
import SmartlistsRoutes from "./Smartlists/Routes.Smartlists.js";
import TicketsRoutes from "./Tickets/Routes.Tickets.js";
import SimpleRoutes from "./Simple/Routes.Simple.js";
import TscRoutes from "./Tsc/Routes.Tsc.js";
import UnitsRoutes from "./Units/Routes.Units.js";
import UsageRoutes from "./Usage/Routes.Usage.js";
import UsersRoutes from "./Users/Routes.Users.js";

let routes = [
	AccountRoutes,
	AssetManagementRoutes,
	CellularRoutes,
	...CustomerDashRoutes,
	DeviceGroupsRoutes,
	DiagnosticsRoutes,
	EngineeringRoutes,
	InstallationsRoutes,
	MonitoringRoutes,
	...NetworkObjectsRoutes,
	NetworkRoutes,
	NotificationsRoutes,
	...OrderingRoutes,
	OtasRoutes,
	ReportingRoutes,
	//SchedulingRoutes,
	SitesRoutes,
	...SimpleRoutes,
	SmartlistsRoutes,
	TicketsRoutes,
	...TscRoutes,
	...UnitsRoutes,
	UsageRoutes,
	UsersRoutes,
	//for any routes that don't match
	{
		path: "/:pathMatch(.*)*",
		redirect: "not-found", //to new 404
	},
];

export default routes;
