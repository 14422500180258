<template>
	<v-skeleton-loader
		v-if="isInitialLoading"
		type="list-item-two-line"
	></v-skeleton-loader>
	<v-autocomplete
		v-else
		v-model="value"
		v-model:search="search"
		v-bind="$attrs"
		:name="name"
		:items="results"
		:loading="loading"
		:variant="$attrs.variant ? $attrs.variant : 'underlined'"
		:color="$attrs.color ? $attrs.color : 'primary'"
		:error-messages="errors"
		:item-title="renderItemText"
		:item-value="renderItemValue"
		:no-filter="!(isCustomer || isProcessor)"
		:hide-selected="hideSelected"
		autocomplete="off"
	>
		<template #item="{ item, props }">
			<!-- header grouping -->
			<template v-if="item.raw.customHeader">
				<h4 class="pl-3" :class="item.raw.class">
					{{ item.raw.customHeader }}
				</h4>
			</template>

			<template v-else>
				<v-list-item v-bind="props" density="compact">
					<template #prepend>
						<v-icon :icon="getPrependListIcon(item.raw.category)"> </v-icon>
					</template>

					<template v-if="item.raw.category === 'Unit'" #append>
						<v-icon
							:icon="getUnitStatusIcon(item.raw.ref)"
							:color="getUnitStatusColor(item.raw.ref)"
						>
						</v-icon>
					</template>

					<!-- unit results -->
					<template v-if="item.raw.category === 'Unit'">
						<v-list-item-subtitle v-if="item.raw.ref.serial">
							<span class="font-weight-bold">Serial: </span>
							{{ item.raw.ref.serial }}
						</v-list-item-subtitle>
						<v-list-item-subtitle
							v-if="item.raw.matched"
							class="itemMatchedDetails"
						>
							Matched by
							<span class="font-italic font-weight-bold">
								{{ item.raw.matched.field }}:
								<!-- eslint-disable-next-line vue/no-v-html -->
								<span v-html="item.raw.matched.data"></span>
							</span>
						</v-list-item-subtitle>
					</template>

					<!-- ticket results -->
					<template v-if="item.raw.category === 'Ticket'">
						<v-list-item-subtitle v-if="item.raw.ref.hostname">
							<span class="font-weight-bold">Hostname: </span>
							{{ item.raw.ref.hostname }}
						</v-list-item-subtitle>
						<v-list-item-subtitle
							v-if="item.raw.matched"
							class="itemMatchedDetails"
						>
							Matched by
							<span class="font-italic font-weight-bold">
								{{ item.raw.matched.field }}:
								<!-- eslint-disable-next-line vue/no-v-html -->
								<span v-html="item.raw.matched.data"></span>
							</span>
						</v-list-item-subtitle>
					</template>

					<!-- module results -->
					<template v-if="item.raw.category === 'Module'">
						<v-list-item-subtitle v-if="item.raw.ref.hostname">
							<span class="font-weight-bold">Hostname: </span>
							{{ item.raw.ref.hostname }}
						</v-list-item-subtitle>
						<v-list-item-subtitle
							v-if="item.raw.matched"
							class="itemMatchedDetails"
						>
							Matched by
							<span class="font-italic font-weight-bold">
								{{ item.raw.matched.field }}:
								<!-- eslint-disable-next-line vue/no-v-html -->
								<span v-html="item.raw.matched.data"></span>
							</span>
						</v-list-item-subtitle>
					</template>

					<!-- network object results -->
					<template v-if="item.raw.category === 'Network Object'">
						<v-list-item-subtitle
							v-if="item.raw.ref.object_type"
							class="itemMatchedDetails"
						>
							<span class="font-weight-bold">Type: </span>
							{{ item.raw.ref.object_type }}
						</v-list-item-subtitle>
						<v-list-item-subtitle v-if="item.raw.matched">
							Matched by
							<span class="font-italic font-weight-bold">
								{{ item.raw.matched.field }}:
								<!-- eslint-disable-next-line vue/no-v-html -->
								<span v-html="item.raw.matched.data"></span>
							</span>
						</v-list-item-subtitle>
					</template>

					<!-- multi site results -->
					<template v-if="item.raw.category === 'Multi-Site'">
						<v-list-item-subtitle
							v-if="item.raw.ref.address"
							class="itemMatchedDetails"
						>
							<span class="font-weight-bold">Address: </span>
							{{ item.raw.ref.address }}
						</v-list-item-subtitle>
						<v-list-item-subtitle v-if="item.raw.matched">
							Matched by
							<span class="font-italic font-weight-bold">
								{{ item.raw.matched.field }}:
								<!-- eslint-disable-next-line vue/no-v-html -->
								<span v-html="item.raw.matched.data"></span>
							</span>
						</v-list-item-subtitle>
					</template>
				</v-list-item>
			</template>
		</template>
		<!-- other custom slots -->
		<template
			v-for="(_, slotName) in $slots"
			:key="slotName"
			#[slotName]="slotProps"
		>
			<slot :name="slotName" v-bind="slotProps || {}" />
		</template>
	</v-autocomplete>
</template>

<script>
import { nanoid } from "nanoid";
import { useField } from "vee-validate";
import { debounce as _debounce } from "lodash-es";

export default {
	name: "GlobalSearchInput",
	//because of skeleton loader
	inheritAttrs: false,
	props: {
		// eslint-disable-next-line vue/require-default-prop
		modelValue: {
			type: [String, Number, Boolean, Array, Object, Date, Function],
		},
		name: {
			type: String,
			default: function () {
				const uid = nanoid();
				return `GlobalSearchInput-${uid}`;
			},
		},
		searchTypes: {
			type: Array,
			required: true,
		},
		searchFields: {
			type: Array,
			default: () => [],
		},
		isGlobalSearch: {
			type: Boolean,
			default: false,
		},
		customerIdFilter: {
			type: [String, Number],
			default: undefined,
		},
		serviceTypeFilter: {
			type: [String, Number],
			default: undefined,
		},
		orphanFilter: {
			type: [String, Number],
			default: undefined,
		},
		unitFilter: {
			type: [String],
			default: undefined,
		},
		useSerial: {
			type: Boolean,
			default: false,
		},
		hideSelected: {
			type: Boolean,
			default: true,
		},
	},
	//need to emit "update:modelValue" because of listener
	//"@update:model-value" on the input
	emits: ["resultsFetched", "update:modelValue"],
	setup(props) {
		const { value, errors, resetField } = useField(
			toRef(props, "name"),
			undefined,
			{
				initialValue: toRef(props, "modelValue"),
				syncVModel: false,
			},
		);
		return {
			value,
			errors,
			resetField,
		};
	},
	data() {
		return {
			isInitialLoading: true,
			loading: false,
			search: null,
			results: [],
			cachedResults: [],
			isCustomer: this.searchTypes.includes("customer"),
			isProcessor: this.searchTypes.includes("processor"),
			hasCustomerProcessor:
				this.searchTypes.includes("customer") &&
				this.searchTypes.includes("processor"),
			isInputDirty: false,
		};
	},
	watch: {
		value: function (newVal) {
			//in the case of reset through <Form>
			//resetForm from <Form> sets to undefined
			//using that hook to call resetField
			if (typeof newVal === "undefined") {
				this.resetField();
			} else if (this.modelValue !== newVal) {
				this.$emit("update:modelValue", newVal);
			}

			// clause her since customer or processor do not need caching
			if (!(this.isCustomer || this.isProcessor)) {
				if (Array.isArray(newVal)) {
					if (newVal.length > 0) {
						this.cachedResults = this.results;
					} else {
						this.cachedResults = [];
					}
				} else if (newVal) {
					this.cachedResults = [newVal];
				} else {
					this.cachedResults = [];
				}
			}
		},
		modelValue: function (newVal) {
			if (this.value !== newVal) {
				this.value = newVal;
				this.$emit("update:modelValue", newVal);
			}
		},
		search(val) {
			// no search case for no value/when input is cleared
			if (!val) {
				return;
			}

			// no search case for single select on input blur
			if (!Array.isArray(this.value) && val && this.value) {
				return;
			}

			// no search case for multi select on input blur
			if (
				Array.isArray(this.value) &&
				!val &&
				this.value &&
				this.value.length
			) {
				return;
			}

			// no search since fetching all of these upfront
			if (this.isProcessor || this.isCustomer) {
				return;
			}

			this.debouncedGetResults();
		},
		customerIdFilter() {
			//clearing out search because of customer change
			if (this.search) {
				this.search = null;
			}

			this.debouncedGetResults();
		},
		serviceTypeFilter() {
			this.debouncedGetResults();
		},
		orphanFilter() {
			this.debouncedGetResults();
		},
		unitFilter() {
			this.debouncedGetResults();
		},
	},
	created() {
		this.debouncedGetResults = _debounce(this.getGlobalSearchResults, 250);

		this.debouncedGetResults(true);
	},
	methods: {
		// Parameters:
		// 'type' - Type to search (can be specified multiple times, or omitted to search unit/module/ticket/customer/processor)
		// 'term' - Search term (if omitted, returns all results)
		// 'field' - Field to search (can be specified multiple times, optional)
		// 'limit' - Result limit (default 100)
		// 'disable_match_data' - don't return match snippets for faster results
		getGlobalSearchResults: function (initialLoad) {
			let unitResults = [];
			let ticketResults = [];
			let moduleResults = [];
			let networkObjectResults = [];
			let multiSiteResults = [];
			let searchTerm = "";

			this.loading = true;

			if (initialLoad && this.value && !(this.isProcessor || this.isCustomer)) {
				searchTerm = this.value;
			} else {
				searchTerm = this.search;
			}

			return $http
				.post(`search/global`, {
					type: this.searchTypes,
					field: this.searchFields,
					term: searchTerm,
					limit: this.isProcessor || this.isCustomer ? 10000 : 100,
					disable_match_data:
						(this.isProcessor || this.isCustomer) && !this.isGlobalSearch
							? 1
							: 0,
					filter_customer_id: this.customerIdFilter,
					service_type_id: this.serviceTypeFilter,
					orphans: this.orphanFilter,
					filter_unit: this.unitFilter,
				})
				.then((response) => {
					if (this.isGlobalSearch) {
						unitResults = response.results.filter(
							(result) => result.category === "Unit",
						);
						if (unitResults.length) {
							unitResults.unshift({
								customHeader: "Unit Results",
								class: "unit_results",
							});
						}

						ticketResults = response.results.filter(
							(result) => result.category === "Ticket",
						);
						if (ticketResults.length) {
							ticketResults.unshift({
								customHeader: "Ticket Results",
								class: "ticket_results",
							});
						}

						moduleResults = response.results.filter(
							(result) => result.category === "Module",
						);
						if (moduleResults.length) {
							moduleResults.unshift({
								customHeader: "Module Results",
								class: "module_results",
							});
						}

						networkObjectResults = response.results.filter(
							(result) => result.category === "Network Object",
						);
						if (networkObjectResults.length) {
							networkObjectResults.unshift({
								customHeader: "Network Object Results",
								class: "network_results",
							});
						}

						multiSiteResults = response.results.filter(
							(result) => result.category === "Multi-Site",
						);
						if (multiSiteResults.length) {
							multiSiteResults.unshift({
								customHeader: "Multi Site Results",
								class: "multisite_results",
							});
						}
						this.results = [
							...unitResults,
							...ticketResults,
							...moduleResults,
							...networkObjectResults,
							...multiSiteResults,
						];
					} else {
						this.results = [...this.cachedResults, ...response.results];
					}
				})
				.catch((err) => {
					console.error("getGlobalSearchResults failed", err);
				})
				.finally(() => {
					this.loading = false;

					if (initialLoad) {
						this.isInitialLoading = false;
					}

					this.$emit("resultsFetched", this.results);
				});
		},
		getUnitStatusIcon: function (ref) {
			if (ref.vrf_id < 0) {
				return "fa-solid fa-circle";
			} else if (ref.status === 0) {
				return "fa-solid fa-down";
			} else if (ref.status === 1) {
				return "fa-solid fa-up";
			} else if (ref.status === 2) {
				return "fa-solid fa-down";
			} else {
				return "fa-solid fa-question";
			}
		},
		getUnitStatusColor: function (ref) {
			if (ref.vrf_id < 0) {
				return "primary";
			} else if (ref.status === 0) {
				return "error";
			} else if (ref.status === 1) {
				return "success";
			} else if (ref.status === 2) {
				return "orange";
			} else {
				return "";
			}
		},
		getPrependListIcon: function (type) {
			if (type === "Unit") {
				return "fa-solid fa-router";
			} else if (type === "Ticket") {
				return "fa-solid fa-ticket";
			} else if (type === "Module") {
				return "fa-solid fa-microchip";
			} else if (type === "Network Object") {
				return "fa-solid fa-network-wired";
			} else if (type === "Multi-Site") {
				return "fa-solid fa-chart-network";
			} else if (type === "Customer") {
				return "fa-solid fa-user-tie";
			} else if (type === "Processor") {
				return "fa-solid fa-chart-network";
			} else if (type === "User") {
				return "fa-solid fa-user";
			} else {
				return "fa-solid fa-question";
			}
		},
		renderItemText: function (item) {
			if (item.category === "Address") {
				return item.ref.address;
			} else if (item.category === "Processor") {
				return `${item.label} [${item.ref.prefix}]`;
			} else if (item.category === "Ticket") {
				return `${item.label} - ${item.ref.title}`;
			} else if (item.category === "Unit") {
				return this.useSerial ? item.ref.serial : item.ref.hostname;
			} else if (item.category === "Network Object") {
				return item.ref.name;
			} else {
				return item.label;
			}
		},
		renderItemValue: function (item) {
			if (item.category === "Address") {
				return item.ref.location_id;
			} else if (item.category === "Customer") {
				return this.hasCustomerProcessor ? item.id : item.ref.id;
			} else if (item.category === "Module") {
				return item.ref.esn;
			} else if (item.category === "Multi-Site") {
				return item.ref.id;
			} else if (item.category === "Processor") {
				return this.hasCustomerProcessor ? item.id : item.ref.id;
			} else if (item.category === "Subnet") {
				return item.ref.subnet_cidr;
			} else if (item.category === "Ticket") {
				return item.ref.idTicket;
			} else if (item.category === "Unit") {
				return this.useSerial ? item.ref.serial : item.ref.hostname;
			} else if (item.category === "Network Object") {
				return item.ref.id;
			} else if (item.category === "User") {
				return item.ref.username;
			} else {
				return item.value;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.selected {
	background-color: var(--v-grey-lighten-1);
}
.unit_results {
	color: rgb(var(--v-theme-typeUnit));
}
.ticket_results {
	color: rgb(var(--v-theme-typeTicket));
}

.module_results {
	color: rgb(var(--v-theme-typeModule));
}

.network_results {
	color: rgb(var(--v-theme-typeNetwork));
}

.multisite_results {
	color: rgb(var(--v-theme-typeMultisite));
}
</style>
