const ROUTES = [
	{
		path: "",
		name: "installations",
		redirect: { name: "installations-history" },
	},
	{
		path: "history",
		name: "installations-history",
		components: {
			default: () => import("./InstallationsHistory.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "History",
			pageTitle: "Installation History",
		},
		children: [
			{
				path: ":installId",
				name: "installations-history-id",
				props: true,
				component: () => import("./InstallationsHistoryId.vue"),
				meta: {
					pageTitle: "Installation History",
					hidePageScope: true,
				},
				beforeEnter: (to, from, next) => {
					if (to.params && to.params.installId) {
						to.meta.pageTitle = `Installation History: ${to.params.installId}`;
						next();
					} else {
						next();
					}
				},
			},
		],
	},
	{
		path: "installation-queue",
		name: "installations-installation-queue",
		components: {
			default: () => import("./InstallationsInstallationQueue.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Installation Queue",
			pageTitle: "Installation Queue",
			requiresPriv: "canStartInstallation",
		},
	},
	{
		path: "replacement-queue",
		name: "installations-replacement-queue",
		components: {
			default: () => import("./InstallationsReplacementQueue.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Replacement Queue",
			pageTitle: "Replacement Queue",
			requiresPriv: "canStartInstallation",
		},
	},
	{
		path: "pending",
		name: "installations-pending",
		components: {
			default: () => import("./InstallationsPending.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Pending Installations",
			pageTitle: "Pending Installations",
			requiresPriv: "canStartInstallation",
		},
		children: [
			{
				path: ":installId",
				name: "installations-pending-id",
				props: true,
				meta: {
					hidePageScope: true,
				},
				component: () => import("./InstallationsPendingId.vue"),
				beforeEnter: (to, from, next) => {
					if (to.params && to.params.installId) {
						to.meta.pageTitle = `Pending Installation: ${to.params.installId}`;
						next();
					} else {
						next();
					}
				},
			},
		],
	},
	{
		path: "statistics",
		name: "installations-statistics",
		component: () => import("./InstallationsStatistics.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Statistics",
			pageTitle: "Installation Statistics",
		},
	},
];

export default {
	path: "/installations",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canViewInstallations",
		navTitle: "Installations",
		pageTitle: "Installations",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
