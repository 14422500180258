const ROUTES = [
	{
		path: "",
		name: "smartlists",
		redirect: { name: "smartlists-inventory" },
	},
	{
		path: "inventory",
		name: "smartlists-inventory",
		component: () => import("./SmartlistsInventory.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Inventory",
			pageTitle: "Smartlist Inventory",
		},
		children: [
			{
				path: ":smartlistId",
				name: "smartlists-inventory-id",
				component: () => import("./SmartlistsId.vue"),
				props: true,
				meta: {
					isShareGroup: false,
				},
				beforeEnter: (to, from, next) => {
					if (to.params && to.params.id) {
						to.meta.pageTitle = `Smartlist: ${to.params.id} - Configure`;
						next();
					} else {
						next();
					}
				},
			},
		],
	},
	{
		path: "sharing-groups",
		name: "sharing-groups",
		component: () => import("./SmartlistsSharingGroups.vue"),
		meta: {
			includeInNav: true,
			requiresPriv: "canViewSharingGroups",
			navTitle: "Sharing Groups",
			pageTitle: "Smartlist Sharing Groups",
		},
		children: [
			{
				path: ":smartlistId",
				name: "sharing-groups-id",
				component: () => import("./SmartlistsId.vue"),
				props: true,
				meta: {
					isShareGroup: true,
				},
				beforeEnter: (to, from, next) => {
					if (to.params && to.params.id) {
						to.meta.pageTitle = `Smartlist: ${to.params.id} - Configure`;
						next();
					} else {
						next();
					}
				},
			},
		],
	},
];

export default {
	path: "/smartlists",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canViewSmartLists",
		navTitle: "Smartlists",
		pageTitle: "Smartlists",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
