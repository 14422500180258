<template>
	<v-card-item :class="background">
		<template #prepend>
			<slot name="icon"></slot>
		</template>
		<v-card-title :class="titleColor">
			<template v-if="title">{{ title }}</template>
			<slot v-else name="title"></slot>
		</v-card-title>
		<v-card-subtitle :class="subtitleColor ? subtitleColor : titleColor">
			<template v-if="subtitle">{{ subtitle }}</template>
			<slot v-else name="subtitle"></slot>
		</v-card-subtitle>
		<template #append>
			<slot name="actions" :color="titleColor"></slot>
			<v-btn
				v-if="!hideCloseButton"
				:class="titleColor"
				icon="fa-solid fa-times"
				variant="text"
				@click="$emit('closeDialog')"
			></v-btn>
		</template>
	</v-card-item>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "",
		},
		subtitle: {
			type: String,
			default: "",
		},
		background: {
			type: String,
			default: "bg-primary-darken-4",
		},
		titleColor: {
			type: String,
			default: "text-primary-lighten-1",
		},
		subtitleColor: {
			type: String,
			default: "",
		},
		hideCloseButton: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["closeDialog"],
	data() {
		return {};
	},
};
</script>

<style lang="scss">
.v-card-item .v-card-subtitle {
	padding: 0;
}
.v-dialog > .v-overlay__content > .v-card > .v-card-item {
	padding: 0.625rem 1rem;
}
.v-dialog .v-card-item + .v-card-text {
	padding-top: 0.5rem;
}
</style>
