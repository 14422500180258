import { setLocale, addMethod, string } from "yup";
import { parsePhoneNumber } from "awesome-phonenumber";

// setting message defaults
setLocale({
	mixed: {
		required: "This field is required",
		notType: function notType(_ref) {
			switch (_ref.type) {
				case "number":
					return "This field must be a number";
				case "string":
					return "This field must be a string";
				case "date":
					return "This field must be a valid date";
				default:
					return "This field is not the correct type";
			}
		},
	},
	string: {
		email: "This field must be a valid email address",
		min: "This field must be at least ${min} characters",
		max: "This field must be at most ${max} characters",
	},
	number: {
		min: "This field must be at least ${min}",
		max: "This field must be at most ${max}",
	},
});

function ipv4() {
	return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
		message: "Invalid IP address",
		excludeEmptyString: true,
	}).test("ip", "Invalid IP address", (value) => {
		return value === null || value === undefined || value.trim() === ""
			? true
			: value.split(".").find((i) => parseInt(i, 10) > 255) === undefined;
	});
}
addMethod(string, "ipv4", ipv4);

function ipv4WithMask() {
	return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})\/(3[0-2]|[1-2]?\d)$)/, {
		message: "Invalid IP address with mask",
		excludeEmptyString: true,
	}).test("ipv4WithMask", "Invalid IP address with mask", (value) => {
		return value === null || value === undefined || value.trim() === ""
			? true
			: value.split(".").find((i) => parseInt(i, 10) > 255) === undefined;
	});
}
addMethod(string, "ipv4WithMask", ipv4WithMask);

function phone(regionCode, errorMessage) {
	const errMsg =
		typeof errorMessage === "string" && errorMessage
			? errorMessage
			: regionCode
				? `Must be a valid phone number for region ${regionCode}`
				: "Must be a valid US phone number";

	return this.test("phone", errMsg, (value) => {
		const phone = parsePhoneNumber(value, {
			regionCode: regionCode ? regionCode : "US",
		});

		if (!value) {
			return true;
		} else {
			return phone.valid;
		}
	});
}
addMethod(string, "phone", phone);

function alpha() {
	return this.matches(/^[A-Za-z ]*$/, {
		message: "Alphabetical characters only",
		excludeEmptyString: true,
	});
}
addMethod(string, "alpha", alpha);

function double() {
	return this.matches(/^[0-9]*\.[0-9]{2}$/, {
		message: "This field must have 2 decimal places",
		excludeEmptyString: true,
	});
}
addMethod(string, "double", double);

function alphaFirst() {
	return this.matches(/^[a-zA-Z][a-zA-Z0-9]+$/, {
		message: "First letter must be alpha, remaining alphanumeric",
		excludeEmptyString: true,
	});
}
addMethod(string, "alphaFirst", alphaFirst);

//minHour and maxHour are required to be integers 0-24
function timeMinMax(minHour, maxHour) {
	const displayMinHour =
		minHour > 12
			? minHour - 12 + ":00 PM"
			: minHour == 0
				? "12:00AM"
				: minHour + ":00 AM";
	const displayMaxHour =
		maxHour > 12
			? maxHour - 12 + ":00 PM"
			: maxHour == 0
				? "12:00AM"
				: maxHour + ":00 AM";
	const errMsg = `You must enter a time between ${displayMinHour} - ${displayMaxHour} `;

	return this.test("timeMinMax", errMsg, (value) => {
		const tempValHour = parseInt(value.slice(0, 2));
		if (tempValHour >= minHour && tempValHour <= maxHour) {
			return true;
		} else {
			false;
		}
	});
}
addMethod(string, "timeMinMax", timeMinMax);
