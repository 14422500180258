import axios from "axios";
import qs from "qs";
import { useUserStore } from "@/stores/store.user";

const BASE_AXIOS_CONFIG = {
	baseURL: "/api/v1/",
	transformRequest: (data) => {
		// used to turn json object into text string
		if (data && data.isFormData) {
			return data.data;
		} else {
			return qs.stringify(data, { arrayFormat: "brackets" });
		}
	},
};

const $axios = axios.create(BASE_AXIOS_CONFIG);

$axios.interceptors.response.use(
	(response) => {
		const req_response = response.data;
		const contentDisposition = response.headers["content-disposition"];
		//error catching when successful but has error within
		if (
			req_response &&
			!req_response.data &&
			req_response.errCode &&
			req_response.errMsg
		) {
			// In the case of the session expiring
			if (
				req_response.errCode === 64 &&
				req_response.errMsg ===
					"You must be logged in to view this information."
			) {
				const userStore = useUserStore();
				if (userStore.loggedIn) {
					//setting path user was on to redirect after login
					window.location.replace(window.location.pathname);
				}
			} else {
				if (!response.config.suppressError) {
					$toast.error(
						`Error ${req_response.errCode}: ${req_response.errMsg}`,
						{
							icon: "fa-solid fa-circle-xmark",
						},
					);
					//console.error("Error", req_response.errCode, req_response.errMsg);
				}
			}
			return Promise.reject(req_response);
		} else if (
			req_response &&
			(req_response.draw ||
				req_response.recordsFiltered ||
				req_response.recordsTotal)
		) {
			//the case for datatables
			return req_response;
		} else if (
			contentDisposition &&
			contentDisposition.search("attachment") > -1
		) {
			let filename;
			const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			const matches = filenameRegex.exec(contentDisposition);
			if (matches != null && matches[1]) {
				filename = matches[1].replace(/['"]/g, "");
			}
			let binaryData = [];
			binaryData.push(response.data);
			const a = document.createElement("a");
			document.body.appendChild(a);
			a.href = window.URL.createObjectURL(new Blob(binaryData));
			a.download = filename;
			a.click();
			a.remove();
		} else {
			return req_response && req_response.data;
		}
	},
	function (error) {
		//do something with actual errors handling here
		return Promise.reject(error);
	},
);

export default $axios;
