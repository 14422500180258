const NAV_LIST = [
	{
		path: "/",
		title: "Home",
		icon: "fa-duotone fa-house",
	},
	{
		path: "/map",
		title: "Map",
		icon: "fa-duotone fa-map-location-dot",
	},
	{
		path: "/asset-management",
		title: "Asset Management",
		icon: "fa-duotone fa-circle",
		subRoutes: [
			{
				path: "/asset-management/address-correction",
				title: "Address Correction",
				icon: "fak fa-solid-location-dot-gear",
				priv: "canCorrectAddresses",
			},
			{
				path: "/asset-management/relocate",
				title: "Relocation",
				icon: "fa-light fa-map-pin",
			},
		],
	},
	{
		path: "/cellular",
		title: "Cellular",
		icon: "fa-solid fa-sim-card",
		priv: "can_view_cellular_plans",
	},
	{
		path: "/customer-dash",
		title: "Customer Dash",
		icon: "fak fa-solid-gauge-circle-user",
	},
	{
		path: "/device-groups",
		title: "Device Groups",
		icon: "fa-duotone fa-chart-network",
		priv: "canViewMultiSites",
	},
	{
		path: "/diagnostics",
		title: "Diagnostics",
		icon: "fa-duotone fa-microscope",
		priv: "canRunDiagnostics",
		subRoutes: [
			{
				path: "/diagnostics/history",
				title: "History",
				icon: "fak fa-solid-microscope-clock",
			},
			{
				path: "/diagnostics/tcp-capture-history",
				title: "TCP Capture History",
				icon: "fak fa-light-microscope-clock",
			},
			{
				path: "/diagnostics/start",
				title: "Start a Test",
				icon: "fa-solid fa-flask-vial",
			},
		],
	},
	{
		path: "/engineering",
		title: "Engineering",
		icon: "fa-duotone fa-compass-drafting",
		priv: "canAccessEngineering",
		subRoutes: [
			{
				path: "/engineering/ip-management",
				title: "IP Management",
				icon: "fak fa-solid-at-gear",
				priv: "canAccessIPMan",
			},
			{
				path: "/engineering/crosstalk/subnet-pairs",
				title: "Crosstalk",
				icon: "fa-solid fa-walkie-talkie",
			},
			{
				path: "/engineering/endpoints",
				title: "Endpoints",
				icon: "fa-light fa-compass-drafting",
				priv: "canAccessIPMan",
			},
			{
				path: "/engineering/rulesets",
				title: "Rulesets",
				icon: "fa-light fa-compass-drafting",
			},
		],
	},
	{
		path: "/installations",
		title: "Installations",
		icon: "fa-duotone fa-screwdriver-wrench",
		priv: "canViewInstallations",
		subRoutes: [
			{
				path: "/installations/history",
				title: "History",
				icon: "fa-solid fa-screwdriver-wrench",
			},
			{
				path: "/installations/pending",
				title: "Pending",
				icon: "fa-light fa-screwdriver-wrench",
				priv: "canStartInstallation",
			},
			{
				path: "/installations/statistics",
				title: "Statistics",
				icon: "fa-light fa-chart-mixed",
			},
		],
	},
	{
		path: "/monitoring",
		title: "Monitoring",
		icon: "fa-duotone fa-monitor-waveform",
		priv: "canAccessMonitoring",
		subRoutes: [
			{
				path: "/monitoring/outages",
				title: "Outages",
				icon: "fa-solid fa-monitor-waveform",
			},
			{
				path: "/monitoring/outage-chart",
				title: "Outage Chart",
				icon: "fa-solid fa-chart-simple",
			},
			{
				path: "/monitoring/failover",
				title: "Failover",
				icon: "fa-light fa-monitor-waveform",
			},
		],
	},
	{
		path: "/network",
		title: "Network",
		icon: "fa-duotone fa-earth-americas",
		priv: "canViewNetwork",
	},
	{
		path: "/network-objects",
		title: "Network Objects",
		icon: "fa-duotone fa-network-wired",
		priv: "canViewDeprecatedNetworkObjectsRoute",
	},
	{
		path: "/notifications",
		title: "Notifications",
		icon: "fa-duotone fa-bell",
		subRoutes: [
			{
				path: "/notifications/manage",
				title: "Manage",
				icon: "fa-solid fa-ballot-check",
			},
			{
				path: "/notifications/history",
				title: "History",
				icon: "fak fa-solid-bell-clock",
			},
			{
				path: "/notifications/create",
				title: "Create",
				icon: "fa-light fa-bell-plus",
				priv: "canCreateNotifications",
			},
		],
	},
	{
		path: "/ordering",
		title: "Ordering",
		icon: "fa-duotone fa-circle-phone",
		priv: "canAccessOrdering",
	},
	{
		path: "/otas",
		title: "OTAs",
		icon: "fa-duotone fa-mobile-signal",
		priv: ["canAccessOTAs", "canViewOTATemplates"],
		subRoutes: [
			{
				path: "/otas/history",
				title: "History",
				icon: "fak fa-solid-wifi-clock",
				priv: "canAccessOTAs",
			},
			{
				path: "/otas/orphans",
				title: "Orphans",
				icon: "fak fa-light-wifi-circle-question",
				priv: "canAccessOTAs",
			},
			{
				path: "/otas/statistics",
				title: "Statistics",
				icon: "fa-light fa-chart-mixed",
				priv: "canAccessOTAs",
			},
			{
				path: "/otas/templates",
				title: "Templates",
				icon: "fa-light fa-border-none",
				priv: "canViewOTATemplates",
			},
		],
	},
	{
		path: "/reporting",
		title: "Reporting",
		icon: "fa-duotone fa-file-chart-pie",
		priv: "canAccessReporting",
		subRoutes: [
			{
				path: "/reporting/history",
				title: "History",
				icon: "fa-regular fa-rectangle-history",
			},
			{
				path: "/reporting/configure",
				title: "Configure",
				icon: "fa-regular fa-layer-plus",
			},
		],
	},
	{
		path: "/sites",
		title: "Sites",
		icon: "fak fa-solid-clipboard-list-circle-check",
		priv: "canAccessQualifications",
		subRoutes: [
			{
				path: "/sites/qualifications/pending",
				title: "Qualifications",
				icon: "fa-solid fa-clipboard-check",
			},
			{
				path: "/sites/surveys/pending",
				title: "Surveys",
				icon: "fa-solid fa-clipboard-list-check",
			},
		],
	},
	{
		path: "/smartlists",
		title: "Smartlists",
		icon: "fa-duotone fa-head-side-brain",
		priv: "canViewSmartLists",
	},
	{
		path: "/tickets",
		title: "Tickets",
		icon: "fa-duotone fa-ticket",
		priv: "canAccessTickets",
	},
	{
		path: "/tsc",
		title: "TSC",
		icon: "fa-duotone fa-life-ring",
		priv: "canAccessTSC",
	},
	{
		path: "/units/all",
		title: "Units",
		icon: "fa-duotone fa-router",
		priv: "canAccessUnits",
	},
	{
		path: "/usage",
		title: "Usage",
		icon: "fa-duotone fa-chart-pie",
		priv: "canAccessUsage",
		subRoutes: [
			{
				path: "/usage/plan-change-history",
				title: "Plan Change History",
				icon: "fak fa-solid-chart-pie-simple-clock",
			},
			{
				path: "/usage/plan-usage",
				title: "Plan Usage",
				icon: "fa-light fa-chart-pie",
			},
			{
				path: "/usage/smartlist-pools",
				title: "Smartlists Sharing Groups",
				icon: "fa-solid fa-circle",
			},
		],
	},
	{
		path: "/users",
		title: "Users",
		icon: "fa-duotone fa-users",
		priv: "canAccessUsers",
	},
];

export { NAV_LIST };
