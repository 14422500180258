const ROUTES = [
	{
		path: "",
		name: "usage",
		redirect: { name: "usage-plan-change-history" },
	},
	{
		path: "plan-change-history",
		name: "usage-plan-change-history",
		components: {
			default: () => import("./UsagePlanChangeHistory.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Plan Change History",
			pageTitle: "Usage Plan Change History",
		},
	},
	{
		path: "plan-usage",
		name: "usage-plan-usage",
		components: {
			default: () => import("./UsagePlanUsage.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Plan Usage",
			pageTitle: "Usage Plan Usage",
		},
	},
	{
		path: "smartlist-pools",
		name: "usage-smartlist-pools",
		components: {
			default: () => import("./UsageSmartlistPools.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Smartlist Sharing Groups",
			pageTitle: "Usage Smartlist Sharing Groups",
		},
	},
];

export default {
	path: "/usage",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canAccessUsage",
		navTitle: "Usage",
		pageTitle: "Usage",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
