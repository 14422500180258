const NETWORK_OBJECTS_ROUTES = [
	{
		path: "",
		name: "network-objects",
		redirect: { name: "network-objects-all" },
	},
	{
		path: "all",
		name: "network-objects-all",
		meta: {
			includeInNav: true,
			navTitle: "All",
			pageTitle: "All Network Objects",
		},
		component: () => import("./NetworkObjectsAll.vue"),
	},
	{
		path: "green-thumb",
		name: "network-objects-gt",
		component: () => import("./NetworkObjectsGreenThumb.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Green Thumb",
			pageTitle: "Green Thumb Network Objects",
		},
	},
	{
		path: "vystar",
		name: "network-objects-vystar",
		component: () => import("./NetworkObjectsVystar.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Vystar",
			pageTitle: "Vystar Network Objects",
		},
	},
	{
		path: "digi",
		name: "network-objects-digi",
		component: () => import("./NetworkObjectsDigi.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Digi",
			pageTitle: "Digi Network Objects",
		},
	},
	{
		path: "solar-winds",
		name: "network-objects-solar-winds",
		component: () => import("./NetworkObjectsSolarWinds.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Solar Winds",
			pageTitle: "Solar Winds Objects",
		},
	},
];
const NETWORK_OBJECTS_ID_ROUTES = [
	{
		path: "",
		redirect: { name: "network-objects-id-info" },
	},
	{
		path: "info",
		name: "network-objects-id-info",
		props: true,
		component: () => import("./NetworkObjectsIdInfo.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Info",
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.networkObjectId) {
				to.meta.pageTitle = `Info: ${to.params.networkObjectId}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "graphs",
		name: "network-objects-id-graphs",
		props: true,
		component: () => import("./NetworkObjectsIdGraphs.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Graphs",
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.networkObjectId) {
				to.meta.pageTitle = `Graphs: ${to.params.networkObjectId}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "tickets",
		name: "network-objects-id-tickets",
		props: true,
		component: () => import("./NetworkObjectsIdTickets.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Tickets",
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.networkObjectId) {
				to.meta.pageTitle = `Tickets: ${to.params.networkObjectId}`;
				next();
			} else {
				next();
			}
		},
	},
];

export default [
	{
		path: "/network-objects",
		component: () => import("@/components/Nav/NavRoute.vue"),
		meta: {
			requiresAuth: true,
			requiresPriv: "canViewDeprecatedNetworkObjectsRoute",
			navTitle: "Network Objects",
			pageTitle: "Network Objects",
		},
		children: NETWORK_OBJECTS_ROUTES,
		props: {
			navItems: NETWORK_OBJECTS_ROUTES,
		},
	},
	{
		path: "/network-objects/:networkObjectId",
		component: () => import("./NetworkObjectsId.vue"),
		meta: { requiresAuth: true, requiresPriv: "canAccessNetworkObjects" },
		props: (route) => ({
			networkObjectId: route.params.networkObjectId,
			navItems: NETWORK_OBJECTS_ID_ROUTES,
		}),
		children: NETWORK_OBJECTS_ID_ROUTES,
	},
];
