<template>
	<v-textarea
		v-model="value"
		:name="name"
		:color="$attrs.color ? $attrs.color : 'primary'"
		:error-messages="errors"
		auto-grow
		autocomplete="off"
	>
		<template
			v-for="(_, slotName) in $slots"
			:key="slotName"
			#[slotName]="slotProps"
		>
			<slot :name="slotName" v-bind="slotProps || {}" />
		</template>
	</v-textarea>
</template>

<script>
import { nanoid } from "nanoid";
import { useField } from "vee-validate";

export default {
	name: "VTextAreaWithValidation",
	props: {
		// eslint-disable-next-line vue/require-default-prop
		modelValue: {
			type: [String, Number, Boolean, Array, Object, Date, Function],
		},
		name: {
			type: String,
			default: function () {
				const uid = nanoid();
				return `VTextAreaWithValidation-${uid}`;
			},
		},
	},
	emits: ["update:modelValue"],
	setup(props) {
		const { value, errors, resetField } = useField(
			toRef(props, "name"),
			undefined,
			{
				initialValue: toRef(props, "modelValue"),
				syncVModel: false,
			},
		);

		return {
			value,
			errors,
			resetField,
		};
	},
	watch: {
		value: function (newVal) {
			//in the case of reset through <Form>
			//resetForm from <Form> sets to undefined
			//using that hook to call resetField
			if (typeof newVal === "undefined") {
				this.resetField();
			} else if (this.modelValue !== newVal) {
				this.$emit("update:modelValue", newVal);
			}
		},
		modelValue: function (newVal) {
			if (this.value !== newVal) {
				this.value = newVal;
				this.$emit("update:modelValue", newVal);
			}
		},
	},
};
</script>
