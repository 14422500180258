const ROUTES = [
	{
		path: "",
		name: "network",
		redirect: { name: "network-top-lists" },
	},
	{
		path: "connection-tracker",
		name: "network-connection-tracker",
		components: {
			default: () => import("./NetworkConnectionTracker.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			requiresPriv: "canAccessMonitoring",
			includeInNav: true,
			navTitle: "Connection Tracker",
			pageTitle: "Network Connection Tracker",
		},
	},
	{
		path: "diagrams",
		name: "network-diagrams",
		components: {
			default: () => import("./NetworkDiagrams.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			requiresPriv: "canViewNetworkMap",
			includeInNav: true,
			navTitle: "Diagrams",
			pageTitle: "Network Diagrams",
		},
	},
	{
		path: "statistics",
		name: "network-statistics",
		redirect: { name: "network-statistics-cellular" },
		components: {
			default: () => import("./NetworkStatistics.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Statistics",
			pageTitle: "Network Statistics",
		},
		children: [
			{
				path: "cellular",
				name: "network-statistics-cellular",
				component: () => import("./NetworkStatisticsCellular.vue"),
				meta: {
					pageTitle: "Network Cellular Statistics",
				},
			},
			{
				path: "down",
				name: "network-statistics-down",
				component: () => import("./NetworkStatisticsDown.vue"),
				meta: {
					pageTitle: "Network Down Statistics",
				},
			},
			{
				path: "wired",
				name: "network-statistics-wired",
				component: () => import("./NetworkStatisticsWired.vue"),
				meta: {
					pageTitle: "Network Wired Statistics",
				},
			},
			{
				path: "protocol",
				name: "network-statistics-protocol",
				component: () => import("./NetworkStatisticsProtocol.vue"),
				meta: {
					pageTitle: "Network Protocol Statistics",
				},
			},
			{
				path: "availability",
				name: "network-statistics-availability",
				component: () => import("./NetworkStatisticsAvailability.vue"),
				meta: {
					pageTitle: "Network Availability Statistics",
				},
			},
		],
	},
	{
		path: "top-lists",
		name: "network-top-lists",
		components: {
			default: () => import("./NetworkTopLists.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Top Lists",
			pageTitle: "Network Top Lists",
		},
	},
];

export default {
	path: "/network",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canViewNetwork",
		navTitle: "Network",
		pageTitle: "Network",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
