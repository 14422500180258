<template>
	<div id="TriLattice" class="genesis-bg-anim"></div>
</template>

<script>
export default {
	data() {
		return {
			columns: 7,
			rows: 4,
		};
	},
	computed: {},
	mounted: function () {
		this.buildLattice(this.columns, this.rows);
	},
	methods: {
		buildLattice: function (nColumns, nRows) {
			// -- find lattice container
			const triLattice = document.getElementById("TriLattice");
			// -- get cell count
			let numOfCells = nColumns * nRows;

			for (var i = 0, n = numOfCells; i < n; ++i) {
				// -- for each cell if the cell is NOT a multiple of the col num, create a big tri
				if (i % nColumns !== 0) {
					let bigTri = document.createElement("b");
					triLattice.appendChild(bigTri);
				}
				// -- if the cell IS a multiple of the col num, create small tri group
				if (i % nColumns === 0) {
					let triGroup = document.createElement("div");
					let triGrid = document.createElement("div");
					triGroup.className = "tri-group";
					triGrid.className = "tri-grid";
					triGroup.appendChild(triGrid);

					// -- crate the s tags for the small tri group
					for (var j = 0; j < 15; ++j) {
						let smallTri = document.createElement("s");
						triGrid.appendChild(smallTri);
					}

					triLattice.appendChild(triGroup);
				}
			}

			// -- hide the downward pointing cells in the first row
			let bSet = triLattice.getElementsByTagName("b");
			for (var k = 0; k <= nColumns; ++k) {
				// -- if k is odd
				if ((k & 1) != 1) {
					bSet[k].className = "hideTri";
				}
			}
		},
	},
};
</script>

<style lang="scss">
.v-theme--GenesisLightTheme .genesis-bg-anim {
	opacity: 0.5;
}
.v-theme--GenesisDarkTheme .genesis-bg-anim {
	opacity: 0.1;
}
.genesis-bg-anim {
	display: grid;
	gap: 59px 4px;
	grid-template-columns: repeat(v-bind("columns"), 61px);
	grid-template-rows: repeat(v-bind("rows"), 44px);
	position: fixed;
	bottom: 24px;
	right: -35px;

	b {
		display: block;
		position: relative;

		&.hideTri::before {
			display: none;
		}

		&::before {
			content: "";
			display: block;
			// width: 100%;
			// height: 100%;
			position: absolute;
			inset: -26px;
		}
		&:nth-child(odd)::before {
			background-color: rgba(var(--v-theme-primary), 1);
		}
		&:nth-child(even)::before {
			background-color: rgba(var(--v-theme-primary), 1);
		}

		&:nth-child(odd)::before {
			clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
		}
		&:nth-child(even)::before {
			clip-path: polygon(50% 100%, 0 0, 100% 0);
		}

		// ---- opacity
		&:nth-child(2n + 2)::before {
			opacity: 0.1;
		}
		&:nth-child(3n)::before {
			opacity: 0.1;
		}
		&:nth-child(3n + 2)::before {
			opacity: 0.2;
		}
		&:nth-child(4n + 5)::before {
			opacity: 0.5;
		}
		&:nth-child(6n + 2)::before {
			opacity: 0.4;
		}
		&:nth-child(8n + 6)::before {
			opacity: 0.2;
		}
		// ---- animation
		&:nth-child(3n) {
			animation: opAnim2 10s ease-in 2s infinite alternate-reverse both;
		}
		&:nth-child(3n + 1) {
			animation: opAnim2 12s ease-in 0s infinite alternate-reverse both;
		}
		&:nth-child(4n + 5) {
			animation: opAnim2 8s ease-in 10s infinite alternate both;
		}
		&:nth-child(4n + 6) {
			animation: opAnim2 14s ease-in 10s infinite alternate-reverse both;
		}
		&:nth-child(4n + 8) {
			animation: opAnim2 16s ease-in 10s infinite alternate both;
		}
		&:nth-child(4n + 4) {
			animation: opAnim2 20s ease-in 8s infinite alternate-reverse both;
		}
		&:nth-child(7n) {
			animation: opAnim2 8s ease-in 3s infinite alternate both;
		}
		&:nth-child(8n + 4) {
			animation: opAnim2 6s ease-in 5s infinite alternate both;
		}
	}

	.tri-group {
		display: block;
		position: relative;
	}
	.tri-grid {
		display: grid;
		gap: 22px 7px;
		grid-template-columns: repeat(5, 13px);
		grid-template-rows: repeat(3, 11px);
		position: absolute;
		inset: -26px;
		margin-top: 10px;
		padding-left: 10px;
	}
	s {
		display: block;
		position: relative;

		&::before {
			content: "";
			display: block;
			position: absolute;
			inset: -9px;
		}
		&:nth-child(odd)::before {
			background-color: rgba(var(--v-theme-primary), 1);
		}
		&:nth-child(even)::before {
			background-color: rgba(var(--v-theme-primary), 1);
		}
	}
	.tri-group:nth-child(odd) s {
		&:nth-child(odd)::before {
			clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
		}
		&:nth-child(even)::before {
			clip-path: polygon(50% 100%, 0 0, 100% 0);
		}
		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6),
		&:nth-child(10) {
			&::before {
				display: none;
			}
		}
	}
	.tri-group:nth-child(2n + 1) s {
		// ---- opacity
		&:nth-child(2n + 2)::before {
			opacity: 0.4;
		}
		&:nth-child(3n + 2)::before {
			opacity: 0.2;
		}
		&:nth-child(3n)::before {
			opacity: 0.1;
		}
		&:nth-child(4n + 5)::before {
			opacity: 0.6;
		}
		&:nth-child(6n + 2)::before {
			opacity: 0.4;
		}
		&:nth-child(8n + 6)::before {
			opacity: 0.6;
		}
		// ---- animation
		&:nth-child(3n + 1) {
			animation: opAnim1 15s ease-in 1s infinite alternate both;
		}
		&:nth-child(4n + 8) {
			animation: opAnim1 15s ease-in 6s infinite both;
		}
		&:nth-child(4n + 4) {
			animation: opAnim1 13s ease-in 5s infinite alternate both;
		}
		&:nth-child(7n) {
			animation: opAnim1 17s ease-in 1s infinite alternate both;
		}
		&:nth-child(8n + 4) {
			animation: opAnim1 10s ease-in 4s infinite both;
		}
	}
	.tri-group:nth-child(even) s {
		&:nth-child(odd)::before {
			clip-path: polygon(50% 100%, 0 0, 100% 0);
		}
		&:nth-child(even)::before {
			clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
		}

		&:nth-child(6),
		&:nth-child(10),
		&:nth-child(11),
		&:nth-child(12),
		&:nth-child(14),
		&:nth-child(15) {
			&::before {
				display: none;
			}
		}
	}
	.tri-group:nth-child(2n) s {
		// ---- opacity
		&:nth-child(2n + 6)::before {
			opacity: 0.4;
		}
		&:nth-child(3n + 3)::before {
			opacity: 0.2;
		}
		&:nth-child(4n + 6)::before {
			opacity: 0.1;
		}
		&:nth-child(6n + 3)::before {
			opacity: 0.4;
		}
		&:nth-child(8n + 6)::before {
			opacity: 0.2;
		}

		// ---- animation
		&:nth-child(3n + 1) {
			animation: opAnim1 15s ease-in 8s infinite alternate both;
		}
		&:nth-child(4n + 8) {
			animation: opAnim1 15s ease-in 3s infinite both;
		}
		&:nth-child(4n + 4) {
			animation: opAnim1 13s ease-in 2s infinite alternate both;
		}
		&:nth-child(7n) {
			animation: opAnim1 17s ease-in 1s infinite both;
		}
		&:nth-child(8n + 4) {
			animation: opAnim1 10s ease-in 7s infinite alternate both;
		}
	}
}

@keyframes opAnim1 {
	0% {
		opacity: 0;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes opAnim2 {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
