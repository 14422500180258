import { defineStore, acceptHMRUpdate } from "pinia";

export const useDockStore = defineStore("dock", {
	state() {
		return {
			alerts: {
				internal: [],
				service: [],
			},
			diagnostics: [],
			installations: [],
			notifications: [],
			otas: [],
			reports: [],
			tcpdumps: [],
			unit_stats: {},
			ticket_stats: {},
			genesis_version: "",
		};
	},
	getters: {
		hasAnyItems: (state) => {
			return (
				state.alerts.internal.length ||
				state.alerts.service.length ||
				state.diagnostics.length ||
				(state.installations && state.installations.length) ||
				state.notifications.length ||
				state.otas.length ||
				state.reports.length ||
				state.tcpdumps.length
			);
		},
	},
	actions: {
		getDockInfo() {
			return $http
				.get(`dockinfo`, {
					params: {
						unit_stats: true,
						ticket_stats: true,
						installations: true,
						notifications: true,
						alerts: true,
					},
				})
				.then((response) => {
					if (response) {
						this.updateDock(response);
					} else {
						//not logged in
					}
				})
				.catch((err) => console.error("getDockInfo failed", err));
		},
		clearNotifications(payload) {
			return $http
				.post(`notifications/clear`, payload)
				.then((response) => {
					if (response.cleared) {
						this.removeNotificationsFromList(payload);
					}
				})
				.catch((err) => console.error("clearNotifications failed", err));
		},
		clearDiagnostics(payload) {
			return $http
				.post(`account/dock`, payload)
				.then((response) => {
					if (response.removed) {
						if (response.removed.diagnostics.length) {
							this.removeDiagnosticsFromList(response.removed.diagnostics);
						}
					}
				})
				.catch((err) => console.error("clearDiagnostics failed", err));
		},
		clearTcpCaptures(payload) {
			return $http
				.post(`account/dock`, payload)
				.then((response) => {
					if (response.removed) {
						if (response.removed.tcpdumps.length) {
							this.removeTcpCapturesFromList(response.removed.tcpdumps);
						}
					}
				})
				.catch((err) => console.error("clearTcpCaptures failed", err));
		},
		clearOtas(payload) {
			return $http
				.post(`account/dock`, payload)
				.then((response) => {
					if (response.removed) {
						if (response.removed.otas.length) {
							this.removeOtasFromList(response.removed.otas);
						}
					}
				})
				.catch((err) => console.error("clearOtas failed", err));
		},
		clearReports(payload) {
			return $http
				.post(`account/dock`, payload)
				.then((response) => {
					if (response.removed) {
						if (response.removed.reports.length) {
							this.removeReportsFromList(response.removed.reports);
						}
					}
				})
				.catch((err) => console.error("clearReports failed", err));
		},
		//converted mutations from vuex
		updateDock(payload) {
			//setting up sorting
			if (payload.alerts) {
				this.alerts = {
					internal: payload.alerts.internal.sort((a, b) => {
						return b.alertStartTime - a.alertStartTime;
					}),
					service: payload.alerts.service.sort((a, b) => {
						return b.lastRun - a.lastRun;
					}),
				};
			}

			if (payload.installations) {
				this.installations = payload.installations.sort((a, b) => {
					return b.update_timestamp - a.update_timestamp;
				});
			}

			this.diagnostics = payload.diagnostics.sort((a, b) => {
				return b.timestamp - a.timestamp;
			});

			this.notifications = payload.notifications.sort((a, b) => {
				return b.create_ts - a.create_ts;
			});

			this.otas = payload.otas.sort((a, b) => {
				return b.time_started - a.time_started;
			});

			this.reports = payload.reports.sort((a, b) => {
				return b.started_ts - a.started_ts;
			});

			this.tcpdumps = payload.tcpdumps.sort((a, b) => {
				return b.time_started - a.time_started;
			});

			this.unit_stats = payload.unit_stats;

			this.ticket_stats = payload.ticket_stats;

			this.genesis_version = payload.genesis_version;
		},
		removeNotificationsFromList(payload) {
			if (payload.clear_all) {
				this.notifications = [];
			} else {
				this.notifications = this.notifications.filter(
					(item) => item.id !== payload.idNotif,
				);
			}
		},
		removeDiagnosticsFromList(payload) {
			const parsedPayload = payload.map((id) => parseInt(id));
			this.diagnostics = this.diagnostics.filter(
				(item) => !parsedPayload.includes(item.testID),
			);
		},
		removeOtasFromList(payload) {
			const parsedPayload = payload.map((id) => parseInt(id));
			this.otas = this.otas.filter(
				(item) => !parsedPayload.includes(item.idJob),
			);
		},
		removeReportsFromList(payload) {
			const parsedPayload = payload.map((id) => parseInt(id));
			this.reports = this.reports.filter(
				(item) => !parsedPayload.includes(item.report_id),
			);
		},
		removeTcpCapturesFromList(payload) {
			const parsedPayload = payload.map((id) => parseInt(id));
			this.tcpdumps = this.tcpdumps.filter(
				(item) => !parsedPayload.includes(item.id),
			);
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useDockStore, import.meta.hot));
}
