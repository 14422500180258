const UNITS_ROUTES = [
	{
		path: "",
		name: "units",
		redirect: { name: "units-all" },
	},
	{
		path: "all",
		name: "units-all",
		components: {
			default: () => import("./UnitsAll.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			requiresAuth: true,
			requiresPriv: "canAccessUnits",
			includeInNav: true,
			navTitle: "Ventus Units",
			pageTitle: "Ventus Units",
		},
		props: (route) => ({ statusCategory: route.query.statusCategory }),
	},
	{
		path: "network-objects-digi",
		name: "units-network-objects-digi",
		component: () => import("@/router/NetworkObjects/NetworkObjectsDigi.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Digi Units",
			pageTitle: "Digi Units",
			requiresPriv: "canAccessNetworkObjects",
		},
	},
	{
		path: "network-objects",
		name: "units-network-objects",
		component: () => import("@/router/NetworkObjects/NetworkObjectsAll.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Network Objects",
			pageTitle: "Network Objects",
			requiresPriv: "canAccessNetworkObjects",
		},
	},
	{
		path: "igt-luxembourg",
		name: "units-igt-luxembourg",
		component: () => import("./UnitsIgtLuxembourg.vue"),
		meta: {
			requiresPriv: "canViewIGTLuxembourgUnits",
			includeInNav: true,
			navTitle: "IGT Luxembourg",
			pageTitle: "IGT Luxembourg Devices",
		},
	},
	{
		path: "igt-multicast",
		name: "units-igt-multicast",
		component: () => import("./UnitsIgtMulticast.vue"),
		meta: {
			requiresCustomer: 210, //IGT CID
			includeInNav: true,
			navTitle: "IGT Multicast",
			pageTitle: "IGT Multicast Devices",
		},
	},
];
const UNITS_HOSTNAME_ROUTES = [
	{
		path: "",
		name: "units-hostname",
		redirect: { name: "units-hostname-info" },
	},
	{
		path: "info",
		name: "units-hostname-info",
		props: true,
		component: () => import("./UnitsHostnameInfo.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Info",
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.hostname) {
				to.meta.pageTitle = `Info: ${to.params.hostname}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "graphs",
		name: "units-hostname-graphs",
		props: (route) => ({
			hostname: route.params.hostname,
			graphType: route.query.graphType,
		}),
		component: () => import("./UnitsHostnameGraphs.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Graphs",
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.hostname) {
				to.meta.pageTitle = `Graphs: ${to.params.hostname}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "settings-commands",
		name: "units-hostname-settings-commands",
		props: true,
		component: () => import("./UnitsHostnameSettingsCommands.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Settings/Commands",
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.hostname) {
				to.meta.pageTitle = `Settings/Commands: ${to.params.hostname}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "logs",
		name: "units-hostname-logs",
		props: true,
		component: () => import("./UnitsHostnameLogs.vue"),
		meta: {
			requiresPriv: "hasInternalAccess",
			includeInNav: true,
			navTitle: "Logs",
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.hostname) {
				to.meta.pageTitle = `Logs: ${to.params.hostname}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "tickets",
		name: "units-hostname-tickets",
		props: true,
		component: () => import("./UnitsHostnameTickets.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Tickets",
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.hostname) {
				to.meta.pageTitle = `Tickets: ${to.params.hostname}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "events",
		name: "units-hostname-events",
		props: true,
		meta: {
			includeInNav: true,
			navTitle: "Events",
		},
		component: () => import("./UnitsHostnameEvents.vue"),
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.hostname) {
				to.meta.pageTitle = `Plan Changes: ${to.params.hostname}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "location-details",
		name: "units-hostname-location-details",
		props: true,
		component: () => import("./UnitsHostnameLocationDetails.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Location Details",
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.hostname) {
				to.meta.pageTitle = `Location Details: ${to.params.hostname}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "ota",
		name: "units-hostname-ota",
		props: true,
		component: () => import("./UnitsHostnameOta.vue"),
		meta: { requiresPriv: "canOTA", includeInNav: true, navTitle: "OTA" },
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.hostname) {
				to.meta.pageTitle = `OTA: ${to.params.hostname}`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "installation-photos",
		name: "units-hostname-installation-photos",
		props: true,
		component: () => import("./UnitsHostnameInstallationPhotos.vue"),
		meta: { includeInNav: true, navTitle: "Installation Photos" },
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.hostname) {
				to.meta.pageTitle = `Installation Photos: ${to.params.hostname}`;
				next();
			} else {
				next();
			}
		},
	},
];

export default [
	{
		path: "/units",
		component: () => import("@/components/Nav/NavRoute.vue"),
		props: {
			navItems: UNITS_ROUTES,
			requiresAuth: true,
			requiresPriv: "canAccessUnits",
			navTitle: "Units",
			pageTitle: "Units",
		},
		children: UNITS_ROUTES,
	},
	{
		path: "/units/:hostname",
		component: () => import("./UnitsHostname.vue"),
		meta: { requiresAuth: true, requiresPriv: "canAccessUnitDash" },
		props: (route) => ({
			hostname: route.params.hostname,
			navItems: UNITS_HOSTNAME_ROUTES,
		}),
		children: UNITS_HOSTNAME_ROUTES,
	},
];
