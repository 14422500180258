const ROUTES = [
	{
		path: "",
		name: "asset-management",
		redirect: { name: "asset-management-address-correction" },
	},
	{
		path: "address-correction",
		name: "asset-management-address-correction",
		components: {
			default: () => import("./AssetManagementAddressCorrection.vue"),
			pageScope: () => import("@/components/PageScope.vue"),
		},
		meta: {
			includeInNav: true,
			navTitle: "Address Correction",
			pageTitle: "Address Correction",
			requiresPriv: "canCorrectAddresses",
		},
	},
	{
		path: "relocate",
		name: "asset-management-relocate",
		component: () => import("./AssetManagementRelocate.vue"),
		props: (route) => ({ hostname: route.query.hostname }),
		meta: {
			includeInNav: true,
			navTitle: "Relocation",
			pageTitle: "Relocation",
		},
	},
];

export default {
	path: "/asset-management",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		navTitle: "Asset Management",
		pageTitle: "Asset Management",
		requiresAuth: true,
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
